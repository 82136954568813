<script>
  export default{
    props: [ "options", "size", "location" ],
    data(){
      return{
        show_options: false,
      }
    },
    computed: {
      active_options(){
        if(this.options && this.options.length > 0 && typeof this.options[0] == "string"){
          return this.options.map(val=>{
            return { display: val, value: val };
          })
        }else{
          return this.options;
        }
      },
    },
    methods: {
      selected(opt){
        if(typeof opt.value === 'string'){
          this.$emit(opt.value.toLowerCase().replace(/ /g, '-'), opt.value);
        }
        this.$emit('selected', opt.value);
        this.show_options = false;
      }
    }
  }
</script>

<template>
  <div class="select-container" :class="{'active': show_options, 'default': !$slots.default}">
    <div class="card-options" @click="show_options = !show_options">
      <slot>
        <div class="fa fa-ellipsis-h" :class="size"></div>
      </slot>
    </div>
    <div class="options-container" :class="location" v-if="show_options" v-click-outside="()=>{ show_options = false }">
      <slot name="info"></slot>
      <div v-for="(opt, index) in active_options" :key="index">
        <div v-if="opt.value" class="option" @click="selected(opt)">
          <span class="fa fa-caret-up" v-if="opt.value == 'ascending'"></span>
          <span class="fa fa-caret-down" v-if="opt.value == 'descending'"></span>
          {{opt.display ? opt.display : opt.value}}
        </div>
        <div v-else class="divider"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import "~@/styles/constants";
  .select-container{
    z-index: 10;
    position: relative;
    &.default{
      padding: 0px 10px;
    }
    &.active{
      z-index: 15;
    }
    .card-options{
      // font-size: 24px;
      color: #FFF;
      &:hover, &.hover{
        color: #CCC;
      }
      * {
        pointer-events: none;
      }
    }
    .options-container{
      position: absolute;
      top: calc(100% + 0px);
      right: 0px;
      min-width: 120px;
      background-color: $black_2;
      box-shadow: $z_depth_3;
      padding: 8px 0px;
      user-select: none;
      font-size: 16px;
      z-index: 160;
      &.right{
        right: auto;
        left: 0px;
      }
      .title{
        padding: 5px 15px;
        margin-left: 20px;
        color: #AAA;
        font-size: 14px;
      }
      .divider{
        border-bottom: 1px solid $black_4;
        margin: 8px 0px;
      }
      .option{
        display: flex;
        align-items: center;
        padding: 0.75rem 2rem;
        // font-size: 14px;
        white-space: nowrap;
        &:hover, &.hover{
          background-color: $button-secondary-hover;
          cursor: pointer;
        }
        * {
          pointer-events: none;
        }
        &:active{
          background-color: $button-secondary-active;
          color: $p;
        }
        .fa{
          font-size: 14px;
          width: 22px;
          color: $p;
        }
      }
    }
  }
</style>