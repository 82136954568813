<script>
  import In from "@/components/atoms/In.vue";
  export default{
    components: { In },
    props: [ "title", "value", "type", "autocomplete", "icon" ],
    computed: {
      active_value: {
        get(){
          return this.value;
        },
        set(val){
          this.$emit("update:value", val);
          this.$emit("input", val);
        }
      },
    }
  }
</script>

<template>
  <div class="form-input">
    <div class="title">
      {{title}}
    </div>
    <in v-model="active_value" class="input" :type="type" :autocomplete="autocomplete" :icon="icon"></in>
  </div>
</template>

<style lang="scss" scoped>
  @import "~@/styles/constants";
  .form-input{
    .title{
      // font-size: 12px;
      padding: 2px 4px;
      margin-bottom: 4px;
    }
  }
</style>