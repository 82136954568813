<script>
import Vuescroll from 'vuescroll';

import CardSection from "@/components/atoms/CardSection";
import PartCard from "@/components/atoms/PartCard";

const MAX_CARDS = 3;

export default {
  components: { CardSection, PartCard, Vuescroll },
  mounted(){
    this.$gtag.event('view_categories', {'event_category': 'flow'});
  },
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {
          scrollingY: false
        },
        rail: {
          size: "20px"
        },
        bar: {
          size: "12px",
          background: "#666"
        }
      }
    }
  },
  methods: {
    limitedPartsByCategory(category) {
      return this.$store.get("parts/partsByCategory", category)
    },
  },
};
</script>

<template>
  <div id="categories">
    <div class="card-section" v-for="(category, index) in $store.get('parts/categories')" :key="index">
      <div class="section-title-area" @click="$router.push('/parts/categories/'+category.id)">
        <div class="section-title">
          {{ category.name }} <span class="count">({{$store.get('parts/getPartsCount', category)}})</span>
        </div>
        <div class="view-all">View All</div>
      </div>
      <div class="scroll-container" :class="{['has-cards']: limitedPartsByCategory(category).length > 0}">
        <Vuescroll :ops="ops">
          <div class="card-row">
            <PartCard
              v-for="(part, part_index) in limitedPartsByCategory(category)"
              :key="index+'-'+part_index"
              :part="part"
              :link="'/parts/categories/'+category.id+'/'+part.id"
            />
          </div>
        </Vuescroll>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/styles/constants";
.card-section {
  .section-title-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 3rem 1.25rem 3rem;
    &:hover, &.hover {
      background-color: $black_2;
    }
    * {
      pointer-events: none;
    }
    .section-title {
      font-size: 1.5rem;
      .count{
        margin-left: 0.25rem;
        color: #888;
      }
    }
    .view-all {
      font-size: 1.25rem;
    }
  }
}

.card-row{
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0rem 2rem;
}
.scroll-container{
  &.has-cards{
    height: 30rem;
  }
}
</style>