import firebase from 'firebase'
import store from './store'

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyB7UQfWKxguhQqBtOKgSNQZCSVa7bJzqfQ",
  authDomain: "alloyxr.firebaseapp.com",
  databaseURL: "https://alloyxr-default-rtdb.firebaseio.com",
  projectId: "alloyxr",
  storageBucket: "alloyxr.appspot.com",
  messagingSenderId: "403554201836",
  appId: "1:403554201836:web:aedf56c2f4a7344ba535c9",
  measurementId: "G-5H9Y3K9W65"
};
firebase.initializeApp(firebaseConfig)

firebase.firestore().enablePersistence().catch((err) => {
  console.log(err.code);
  if (err.code == 'failed-precondition') {
    console.log("Multiple tabs open, persistence can only be enabled in one tab at a a time.");
  } else if (err.code == 'unimplemented') {
    console.log("The current browser does not support all of the features required to enable persistence");
  }
});

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()
const functions = firebase.functions()

// collection references
const parts = db.collection('parts')
const partCategories = db.collection('partCategories')

// export utils/refs
export {
  db,
  auth,
  storage,
  functions,
  parts,
  partCategories,
}
