export function removeNullProperties(obj){
  const newObj = {};
  Object.keys(obj).forEach(key => {
    if(obj[key] != null){
      newObj[key] = obj[key];
    }
  });
  return newObj;
}

export function incrementKey(key, object){
  if(object[key]){
    object[key].quantity++;
  }else{
    object[key] = {
      quantity: 1,
      parts: {}
    };
  }
}

