import Vue from 'vue'
import Vuex from 'vuex'
import Pathify from './pathify.js'
import { make } from 'vuex-pathify'

import parts from './modules/parts';
import auth from './modules/auth';
import saveFiles from './modules/saveFiles';
import payment from './modules/payment';
import { event } from 'vue-gtag';
import * as firebase from '@/firebase.js'

Vue.use(Vuex)

const state = {
  confirmations: [],
  vr: false,
  useMetric: null,
  // selectedVendor: null,
  roundLength: null,
  roundPosition: null,
  roundLengthInchIncrement: null,
  roundLengthMeterIncrement: null,
  roundPositionInchIncrement: null,
  roundPositionMeterIncrement: null,
  rail_height_filter: null,
  series_filter: null,
  profile_filter: null,
  construction_filter: null,
  key_callback: null,
  show_terms_of_use: false,
  show_privacy_policy: false,
  show_trail_ending_msg: true,
}

const getters = {
  ...make.getters([...Object.keys(state)]),
  filterCount(state){
    let count = 0;
    if(state.rail_height_filter){
      count ++;
    }
    if(state.series_filter){
      count ++;
    }
    if(state.profile_filter){
      count ++;
    }
    if(state.construction_filter){
      count ++;
    }
    return count
  }
}

const mutations = {
  ...make.mutations([...Object.keys(state)]),
  confirm(state, alert){
    state.confirmations.push(alert);
  },
  resolve(state){
    state.confirmations.shift();
  },
  clearFilters(state){
    event('clear_filter', {'event_category': 'flow'});
    state.rail_height_filter = null;
    state.series_filter = null;
    state.profile_filter = null;
    state.construction_filter = null;
  },
  toggleFilter(state, {value, filter}){
    event('toggle_filter', {'event_category': 'flow', 'event_label': filter, 'event_value': `${value}`});
    if (state[filter] == value) {
      state[filter] = null;
    } else {
      state[filter] = value;
    }
  }
}

const actions = {
  loadUserData({commit, rootState}){
    firebase.db.collection("user-data").doc(rootState.auth.user.uid).get().then(doc => {
      if (doc.exists) {
        commit('useMetric', doc.data().useMetric);
        commit('roundLength', doc.data().roundLength);
        commit('roundPosition', doc.data().roundPosition);
        // commit('selectedVendor', doc.data().selectedVendor ? doc.data().selectedVendor : "80/20");
        commit('roundLengthInchIncrement', doc.data().roundLengthInchIncrement || 16);
        commit('roundLengthMeterIncrement', doc.data().roundLengthMeterIncrement || 1000);
        commit('roundPositionInchIncrement', doc.data().roundPositionInchIncrement || 16);
        commit('roundPositionMeterIncrement', doc.data().roundPositionMeterIncrement || 1000);
      } else {
        commit('useMetric', true);
        commit('roundLength', true);
        commit('roundPosition', true);
        // commit('selectedVendor', "80/20");
        commit('roundLengthInchIncrement', 16);
        commit('roundLengthMeterIncrement', 1000);
        commit('roundPositionInchIncrement', 16);
        commit('roundPositionMeterIncrement', 1000);
        console.error("Could not load user data!");
      }
    }).catch(response => {
      console.error(response);
    })
  },
  updateUserData({rootState }, {data, undo}){
    firebase.db.collection("user-data").doc(rootState.auth.user.uid).set({
      ...data
    }, { merge: true }).catch(response => {
      if(undo){
        undo();
      }
    })
  },
  setUseMetric({state, commit, dispatch, rootState}, value){
    const lastValue = state.useMetric;
    commit('useMetric', value);
    dispatch('updateUserData', {
      data: {useMetric: value},
      undo: () => {
        commit('useMetric', lastValue);
      }
    })
  },
  setUserDataValue({state, commit, dispatch, rootState}, {key, value}){
    const lastValue = state[key];
    commit(key, value);
    dispatch('updateUserData', {
      data: {[key]: value},
      undo: () => {
        commit(key, lastValue);
      }
    })
  },
  postMessage({}, message){
    return new Promise((resolve, reject) => {
      if(window.vuplex){
        window.vuplex.postMessage(message);
        resolve(true)
      }else{
        console.log("window.vuplex does not exist in this context.")
        reject(false)
      }
    })
  }
}

export default new Vuex.Store({
  plugins: [Pathify.plugin],
  modules: {
    parts,
    auth,
    saveFiles,
    payment
  },
  state,
  getters,
  mutations,
  actions,
})
