export function metersToInches(value, metric){
  const meters = Number(value);
  if(metric){
    if(meters > 1){
      return Math.round(meters * 1000) / 1000 + "<span class='unit'>m</span>"
    }else{
      return Math.round(meters * 1000) + "<span class='unit'>mm</span>"
    }
  }else{
    const denominator = 128;
    const feet = meters * 3.280839895;

    let ft = Math.floor(feet);
    let inchs = Math.floor((feet - ft) * 12);
    let remaninder = Math.round((((feet - ft) * 12) - inchs) * denominator);

    if(feet - ft > 0.99999){
        ft = Math.ceil(feet);
        inchs = 0;
        remaninder = 0;
    }

    if(((feet - ft) * 12) - inchs > 0.99999){
        inchs = Math.ceil((feet - ft) * 12);
        remaninder = 0;
    }

    const gcd = getGcd(remaninder, denominator);

    const ftString = ft > 0 ? ft+"<span class='unit'>ft</span> " : "";
    const inString = inchs > 0 ? inchs+"" : "";
    const remaninderString = remaninder > 0 && remaninder / denominator != 1 ? "-"+remaninder/gcd+"/"+denominator/gcd+"<span class='unit'>in</span>" : inchs > 0 ? "<span class='unit'>in</span>" : "";

    return ftString + inString + remaninderString;
  }
  // return (Number(value) * 39.3701) +'in';
}

export function getGcd(_x, _y){
  let x = Math.abs(_x);
  let y = Math.abs(_y);
  while(y > 0){
    let t = y;
    y = x % y;
    x = t;
  }
  return x;
}

export function convertValueToMeters(price){
  if(price.includes("mm")){
    return getNumber(price) * 1000;
  }else if(price.includes("cm")){
    return getNumber(price) * 100;
  }else if(price.includes("m")){
    return getNumber(price);
  }else if(price.includes("in")){
    return getNumber(price) * 39.3701;
  }else if(price.includes("ft")){
    return getNumber(price) * 3.28084
  }else{
    return getNumber(price);
  }
}
export function convertValueToSquareMeters(price){
  if(price.includes("mm")){
    return getNumber(price) * 1000000;
  }else if(price.includes("cm")){
    return getNumber(price) * 10000;
  }else if(price.includes("m")){
    return getNumber(price);
  }else if(price.includes("in")){
    return getNumber(price) * 1550;
  }else if(price.includes("ft")){
    return getNumber(price) * 10.7639
  }else{
    return getNumber(price);
  }
}

export function getNumber(price){
  if(price && String(price).match(/\d.*/g)){
    return parseFloat(String(price).match(/\d.*/g).join(""));
  }else{
    return 0
  }
}
export function round(num){

  return Math.round(num * 10000) / 10000
}
export function roundDollars(num){
  return Math.round(num * 100) / 100
}