import Vue from 'vue'
import { dispatch, make } from 'vuex-pathify'
import router from '@/router'
import * as firebase from '@/firebase.js'
import * as Units from '@/lib/units';
import * as Objects from '@/lib/objects';

const state = {
  saveFiles: [],
  saveImages: {},
  saving: false,
  saved: false,
  saving_error: null,
  loading: false,
  activeSaveFileId: null,
}

const getters = {
  ...make.getters([...Object.keys(state)]),
  saveFiles: (state) => {
    return state.saveFiles.sort((a, b) => ((new Date(a.time) > new Date(b.time)) ? -1 : 1));
  },
  saveFilesById: (state) => (id) => {
    return state.saveFiles.find(saveFile => {
      return saveFile.id === id
    });
  },
  getPartLedger: (state, getters) => (saveFileId) => {
    const parts = {};
    const saveFile = getters.saveFilesById(saveFileId);
    if(saveFile.parts){
      saveFile.parts.forEach(part => {
        const key = getters.getPartLedgerKey(part);
        
        Objects.incrementKey(key, parts);

        if(part.snapObjectData.snapAreaData){
          part.snapObjectData.snapAreaData.forEach(area => {
            if(area.snapRegionData){
              area.snapRegionData.forEach(region => {
                if(region.boltHoleData){
                  region.boltHoleData.forEach(boltHole => {
                    if(boltHole.boltSetData.id){
                      if(boltHole.boltSetData.id.includes('&')){
                        boltHole.boltSetData.id.split('&').forEach(id => {
                          Objects.incrementKey(id, parts[key].parts);  
                        })
                      }else{
                        Objects.incrementKey(boltHole.boltSetData.id, parts[key].parts);
                      }
                    }
                  })
                }
                if(region.boltCapData){
                  region.boltCapData.forEach(boltCap => {
                    if(boltCap.boltSetData.id){
                      Objects.incrementKey(boltCap.boltSetData.id, parts[key].parts);
                    }
                  })
                }
              })
            }
          })
        }
      }) 
    }
    
    return parts;
  },
  findSavedPartByLedgerKey: (state, getters) => ({saveFile, ledgerKey}) => {
    return saveFile.parts.find(part => getters.getPartLedgerKey(part) == ledgerKey);
  },
  getPartLedgerKey: (state, getters) => (part) => {
    const width = Units.round(part.meshData.transform.scale?.[0]);
    const height = Units.round(part.meshData.transform.scale?.[1]);
    const length = Units.round(part.meshData.transform.scale?.[2]);

    let key = part.id+"::"+width+","+height+","+length+"";

    if(part.snapObjectData.snapAreaData){
      part.snapObjectData.snapAreaData.forEach(area => {
        if(area.snapRegionData){
          area.snapRegionData.forEach(region => {
            const endTaps = {};
            if(region.boltEndData){
              region.boltEndData.forEach(boltEnd => {
                if(boltEnd.connectedBoltCap && boltEnd.tapSize){
                  endTaps[boltEnd.tapSize] = endTaps[boltEnd.tapSize] ? endTaps[boltEnd.tapSize] + 1 : 1 
                }
              })
            }
            Object.keys(endTaps).forEach(k => {
              if(key.split("::").length > 2){
                key += "," + k +( endTaps[k] > 1 ? " x " + endTaps[k] : "");
              }else{
                key += "::" + k + (endTaps[k] > 1 ? " x " + endTaps[k] : "");
              }
            });
          })
        }
      })
    }

    return key;
  }
}

const mutations = {
  ...make.mutations([...Object.keys(state)]),
  savedFile: (state) => {
    state.saved = true;
    state.saving = false;
    setTimeout(() => {
      state.saved = false;
    }, 2000)
  },
  saveError: (state, value) => {
    state.saving_error = value;
    state.saving = false;
    setTimeout(() => {
      state.saving_error = null;
    }, 5000)
  },
  loaded: (state, id) => {
    state.loading = false;
    state.activeSaveFileId = id;
  },
  cancelLoading: (state) => {
    state.loading = false;
  },
}

const actions = {
  triggerSaveNewProject: ({commit}, {name}) => {
    dispatch('postMessage', "SaveNew:"+name).then(() => {
      commit("saving", true);
    }).catch(() => {

    });
  },
  triggerSaveExistingProject: ({commit}, {id, name}) => {
    dispatch('postMessage', "SaveExisting:"+id+"@"+name).then(() => {
      commit("saving", true);
    }).catch(() => {
      
    });
  },
  saveFile: ({commit, state, rootState, rootGetters}, data) => {
    return new Promise((resolve, reject)=>{
      firebase.db.collection("user-data").doc(rootState.auth.user.uid).collection("save-files").doc(data.id).set(data).then(response => {
        console.log("Saved File! Lets Party!");
        commit("savedFile");
        commit("saveFiles", [...state.saveFiles.filter(saveFile => saveFile.id !== data.id), data]);
        dispatch("postMessage", "SavedFile:"+data.id)
        commit("loaded", data.id);
        resolve();
      }).catch(error => {
        commit("saveError", error);
        dispatch("postMessage", "UnableToSaveFile:"+data.id)
        reject(error);
      })
    })
  },
  loadSaveFiles: ({commit, rootState}) => {
    return new Promise((resolve, reject)=>{
      firebase.db.collection("user-data").doc(rootState.auth.user.uid).collection("save-files").get().then((querySnapshot) => {
        if(!querySnapshot.empty){
          const docs = [];
          querySnapshot.forEach((doc) => {
            docs.push(doc.data())
          });
          commit("saveFiles", docs);
          resolve();
        }else{
          console.log("Empty Collection")
          commit("saveFiles", []);
          resolve();
        }
      }).catch(e => {
        console.log(e)
        reject(e)
      });
    })
  },
  saveImage: ({commit, state, rootState}, {base64String, name}) => {
    return new Promise((resolve, reject)=>{
      fetch("data:image/png;base64,"+base64String).then((res) => {
        return res.arrayBuffer();
      }).then((buf)=>{
        const file = new File([buf], name+".png", {type:"image/png"});
        firebase.storage.ref(`${rootState.auth.user.uid}/${file.name}`).put(file, { customMetadata: {} }).then(response => {
          console.log("Image Uploaded!");
          dispatch("postMessage", "SavedImage:"+file.name);
          resolve(response);
        }).catch(err => {
          console.log(err);
          dispatch("postMessage", "UnableToSaveImage:"+file.name)
          reject(err);
        })
      })
    })
  },
  getImage: ({commit, state, rootState}, name) => {
    if(Object.keys(state.saveImages).includes(name)){
      return new Promise((resolve, reject)=>{
        resolve(state.saveImages[name])
      })
    }
    return new Promise((resolve, reject)=>{
      firebase.storage.ref(`${rootState.auth.user.uid}/${name}.png`).getDownloadURL().then((url) => {
        commit("saveImages", {...state.saveImages, [name]: url})
        resolve(url);
      }).catch(function(error) {
        console.log(error);
        reject(error);
      });
    })
  },
  loadFromCloud: ({commit, state, rootState}, saveFile) =>{
    dispatch('postMessage', "Load:"+JSON.stringify(saveFile)).then(() => {
      commit("loading", true);
    })
  },
  confirmLoad: ({commit}, {id, name}) =>{
    commit('confirm', {
      title: "Unsaved Changes",
      description: "Are you sure you want to discard your changes?",
      start_label: "Cancel",
      stop_label: "Discard",
      start: ()=>{
        commit("cancelLoading");
      },
      stop: ()=>{
        dispatch('postMessage', "LoadOverride:"+id);
      }
    }, {root: true});
  },
  updateCustomField: ({commit, getters, rootState}, {saveFile, ledgerKey, field, value}) =>{
    return new Promise((resolve, reject)=>{
      const data = saveFile[field] ? saveFile[field] : {};

      data[ledgerKey] = value;

      // local save
      if(saveFile[field]){
        saveFile[field][ledgerKey] = value;
      }else{
        saveFile[field] = {
          [ledgerKey]: value
        }
      }
      commit("saveFiles", [...getters.saveFiles.filter(sf => sf.id != saveFile.id), saveFile])

      firebase.db.collection("user-data").doc(rootState.auth.user.uid).collection("save-files").doc(saveFile.id).update({[field]: data}).then(() => {
        console.log('Updated!!!');
      }).catch(e => {
        console.log('Wooomp womp');
        console.log(e)
        reject(e)
      });
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}