import Vue from 'vue'
import { make } from 'vuex-pathify'
import router from '@/router'
import * as firebase from '@/firebase.js'

const state = {
  signed_in: false,
  user: null,
  identity_id: null,
}

const getters = {
  ...make.getters([...Object.keys(state)]),
}

const mutations = {
  ...make.mutations([...Object.keys(state)]),
  wasSignedIn(state, user){
    state.signed_in = true;
    state.user = user;
    localStorage.setItem('previous-user', true)
  },
  wasSignedOut(state){
    state.signed_in = false;
    state.user = null;
  }
}

const actions = {
  checkUserSignIn({commit, dispatch, state, rootState}){
    return new Promise((resolve,reject)=>{
      firebase.auth.onAuthStateChanged(function(user) {
        if (user) {
          commit('wasSignedIn', user);
          dispatch("saveFiles/loadSaveFiles", null, {root: true});
          dispatch("loadUserData", null, {root: true});
          resolve(user);
        } else {
          commit('wasSignedOut')
          reject('noAuthProvided');
        }
      });
    })
  },
  checkUserSubscription({rootState}){
    return new Promise((resolve,reject)=>{
      firebase.db.collection('customers')
      .doc(rootState.auth.user.uid)
      .collection('subscriptions')
      .where('status', 'in', ['trialing', 'active'])
      .onSnapshot(async (snapshot) => {
        if(snapshot.docs.length > 0){
          resolve(snapshot.docs[0].id);
        }else{
          reject()
        }
      });
    })
    
  },
  signOut({commit}){
    firebase.auth.signOut().then(()=>{
      commit('wasSignedOut');
      router.go();
    }).catch(function(error) {
      console.log(error)
    });
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}