export default [
  {
    id: "extrusions",
    name: "Extrusions",
    children: []
  },
  {
    id: "brackets",
    name: "Connectors",
    children: []
  },
  {
    id: "mountingFeet",
    name: "Mounting Feet and Casters",
    children: []
  },
  {
    id: "panels",
    name: "Panels & Mounting",
    children: []
  },
  {
    id: "doors",
    name: "Door Components",
    children: []
  },
  {
    id: "feet",
    name: "Feet",
    children: []
  },
  // {
  //   id: "linear motion",
  //   name: "Linear Motion",
  //   children: []
  // },
  // {
  //   id: "pivots",
  //   name: "Pivots",
  //   children: []
  // },
  {
    id: "organization",
    name: "Organization",
    children: []
  },
  // {
  //   id: "stanchion",
  //   name: "Stanchion",
  //   children: []
  // },
  // {
  //   id: "roller",
  //   name: "Roller Components",
  //   children: []
  // },
  // {
  //   id: "caps",
  //   name: "Covers & Caps",
  //   children: []
  // },
  // {
  //   id: "pressurize",
  //   name: "Pressurize Your Frame",
  //   children: []
  // },
  
]