import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from "vue-gtag";

Vue.config.productionTip = false

Vue.directive('click-outside', { 
  inserted: function (el, binding, vnode) {
    setTimeout(()=>{
      el.clickOutsideEvent = event => {
        if(!(el == event.target || el.contains(event.target))){
          binding.value(event);
        }
      };
      document.body.addEventListener('click', el.clickOutsideEvent)
      document.body.addEventListener("touchstart", el.clickOutsideEvent)
    }, 200)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
    document.body.removeEventListener("touchstart", el.clickOutsideEvent)
  },
});

Vue.use(VueGtag, {
  config: { id: "G-5H9Y3K9W65" }
}, router)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
