import Vue from 'vue'
import { commit, dispatch, make } from 'vuex-pathify'
import router from '@/router'
import * as firebase from '@/firebase.js'
import { event } from 'vue-gtag'
import INVOICE_REQUEST from "@/data/emails/InvoiceRequest.js";

const state = {
  subscribingUser: false,
  showConnectingToStripe: false,
  invoiceError: null
}

const getters = {
  ...make.getters([...Object.keys(state)]),
}

const mutations = {
  ...make.mutations([...Object.keys(state)]),
  
}

const actions = {
  openStripePortal: async ({commit}) => {
    commit('showConnectingToStripe', true);
    const functionRef = firebase.functions.httpsCallable('ext-firestore-stripe-payments-createPortalLink');
    const { data } = await functionRef({
      returnUrl: window.location.href,
      locale: "auto", // Optional, defaults to "auto"
      // configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
    });
    window.location.assign(data.url);
  },
  requestInvoice: async ({commit, rootState}, obj) => {
    return new Promise((resolve, reject)=>{
      console.log("A");
      firebase.db.collection("mail").add({
        to: obj.email,
        message: {
          subject: 'Quadrobee Invoice Request',
          html: INVOICE_REQUEST(obj.email)
        }
      }).then(response => {
        firebase.db.collection("mail").add({
          to: 'colby@quadrobee.com',
          message: {
            subject: 'Invoice Request 🤑🤑🤑',
            text: JSON.stringify(obj)
          }
        }).then(response => {
          resolve()
        }).catch(error => {
          dispatch("postMessage", "Request failed: "+error)
          reject(error);
        })
      }).catch(error => {
        dispatch("postMessage", "Request failed: "+error)
        reject(error);
      })
    })
  },
  subscribeUser: async ({commit, rootState}, price) => {
    commit('showConnectingToStripe', true);
    event('begin_checkout')
    const docRef = await firebase.db
      .collection('customers')
      .doc(rootState.auth.user.uid)
      .collection('checkout_sessions')
      .add({
        price: price,
        // automatic_tax: true,
        // tax_id_collection: true,
        success_url: window.location.origin+'/#/payment-success',
        cancel_url: window.location.href,
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        commit('showConnectingToStripe', false);
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  }
  
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}