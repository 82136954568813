<script>
  import In from "@/components/atoms/In";
  import Btn from "@/components/atoms/Btn";
  import Banner from "@/components/atoms/Banner";
  import Check from "@/components/atoms/Check";
  import SelectOption from '@/components/atoms/SelectOption.vue'

  export default {
    components: { In, Btn, Banner, Check, SelectOption },
    computed: {
      increment_options(){
        if(this.$store.get('useMetric')){
          return [
            {display: "10cm", value: 10},
            {display: "45mm", value: 22.22222222},
            {display: "20mm", value: 50},
            {display: "10mm", value: 100},
            {display: "5mm", value: 200},
            {display: "1mm", value: 1000},
          ]
        }else{
          return [
            {display: "1in", value: 1},
            {display: "1/2in", value: 2},
            {display: "1/4in", value: 4},
            {display: "1/8in", value: 8},
            {display: "1/16in", value: 16},
            {display: "1/32in", value: 32},
            {display: "1/64in", value: 64},
          ]
        }
      },
      roundLengthIncrement(){
        return this.getDisplayFromValue(this.$store.get('useMetric') ? this.$store.get('roundLengthMeterIncrement') : this.$store.get('roundLengthInchIncrement'))
      },
      roundPositionIncrement(){
        return this.getDisplayFromValue(this.$store.get('useMetric') ? this.$store.get('roundPositionMeterIncrement') : this.$store.get('roundPositionInchIncrement'))
      }
    },
    methods:{
      setUserDataValue(key, value){
        this.$store.dispatch("setUserDataValue", {
          key,
          value
        })
      },
      setRoundLengthIncrement(value){
        if(this.$store.get('useMetric')){
          this.setUserDataValue('roundLengthMeterIncrement', value);
        }else{
          this.setUserDataValue('roundLengthInchIncrement', value);
        }
      },
      setRoundPositionIncrement(value){
        if(this.$store.get('useMetric')){
          this.setUserDataValue('roundPositionMeterIncrement', value);
        }else{
          this.setUserDataValue('roundPositionInchIncrement', value);
        }
      },
      getDisplayFromValue(value){
        const opt = this.increment_options.find(opt => (opt.value == value));
        return opt ? opt.display : value;
      },
    }
  }
</script>

<template>
  <Banner>
    <div id="settings-page">
      <div class="settings-column">
        <div class="settings-title">
          App Settings
        </div>
        <div class="settings-module">
          <div class="section">
            <div class="section-title">
              Units
            </div>
            <div class="section-sub-title">
              *This will not change any existing parts in your projects
            </div>
            <br>
            <div class="input-row">
              <div class="check-box-area">
                <Check :value="$store.get('useMetric')" @input="$store.dispatch('setUseMetric', $event)"></Check>
                <div>
                  <div class="name">Use metric system</div>
                  <div class="details">Display units in meters</div>
                </div>
              </div>
            </div>
            <br><br>
            <div class="input-row">
              <div class="check-box-area">
                <Check :value="$store.get('roundLength')" @input="setUserDataValue('roundLength', $event)"></Check>
                <div>
                  <div class="name">Round extrusions</div>
                  <div class="details">This will make it easier to cut and assemble your parts</div>
                </div>
              </div>
            </div>
            <div class="input-row">
              <div class="name">Round extrusions to nearest</div>
              <select-option location="right" :options="increment_options" @selected="setRoundLengthIncrement($event)"><btn type="outline" color="secondary" size="small">{{roundLengthIncrement}}<span class="fa fa-caret-down"></span></btn></select-option>
            </div>
            <br><br>
            <div class="input-row">
              <div class="check-box-area">
                <Check :value="$store.get('roundPosition')" @input="setUserDataValue('roundPosition', $event)"></Check>
                <div>
                  <div class="name">Round position when moving along axis</div>
                  <div class="details">This will help align object</div>
                </div>
              </div>
            </div>
            <div class="input-row">
              <div class="name">Round position to nearest</div>
              <select-option location="right" :options="increment_options" @selected="setRoundPositionIncrement($event)"><btn type="outline" color="secondary" size="small">{{roundPositionIncrement}}<span class="fa fa-caret-down"></span></btn></select-option>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Banner>
</template>

<style lang="scss" scoped>
@import "~@/styles/constants";
#settings-page{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  .settings-column{
    flex-basis: 60rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;
    .settings-title{
      margin-top: 2rem;
      margin-bottom: 2rem;
      font-size: 2rem;
    }
    .settings-module{
      width: 100%;
      background-color: $black_3;
      box-shadow: $z_depth_2;
      border-radius: 0.5rem;
      padding: 3rem;
      .section{
        margin-bottom: 4rem;
        .section-title{
          margin-bottom: 0.25rem;
          font-size: 1.5rem;
        }
        .section-sub-title{
          margin-bottom: 1.5rem;
          color: $w_1;
        }
        .input-row{
          margin-bottom: 1.5rem;
          .name{
            font-size: 1.125rem;
          }
          .details{
            color: $w_1;
            font-size: 1rem;
          }
          .check-box-area{
            display: flex;
            align-items: flex-start;
            .checkbox {
              margin-right: 1rem;
              padding: 0.75rem;
            }
          }
          .select-container{
            margin-top: 0.75rem;
            .fa-caret-down{
              margin-right: 0rem;
              margin-left: 0.5rem;
            }
          }
        }
      }
    }
  }
}
</style>