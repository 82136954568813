<script>
  export default{
    props: ["value", "type", "autocomplete", "icon", "placeholder", "autofocus", "customref"],
    created(){
      this.value_copy = this.value;
      
    },
    mounted(){
      if(this.autofocus){
        this.$refs[this._uid].focus();
      }
    },
    data(){
      return {
        value_copy: this.value,
      }
    },
    computed: {
      active_value: {
        get(){
          return this.value;
        },
        set(val){
          if(this.type == 'number' && val < 0){
            this.$emit("update:value", 0);
            this.$emit("input", 0);
          }else{
            this.$emit("update:value", val);
            this.$emit("input", val);
          }
        }
      },
    },
    methods: {
      focus(){
        this.$store.dispatch('postMessage', "keyboard:keyboard");
        this.$store.commit('key_callback', (key) => {
          const selectionStart  = this.$refs[this._uid].selectionStart;
          const selectionEnd  = this.$refs[this._uid].selectionEnd;

          const deleteChars = Math.abs(selectionEnd - selectionStart);

          if (deleteChars > 0)
          {
            const caretPosStart = Math.min(selectionEnd, selectionStart);
            const caretPosEnd = Math.max(selectionEnd, selectionStart);

            this.active_value = this.active_value.slice(0, caretPosStart) + this.active_value.slice(caretPosEnd)

            this.$nextTick(() => {
              this.$refs[this._uid].setSelectionRange(caretPosStart, caretPosStart);
            });
          }

          if(key === "ENTER"){
            console.log("ENTER");
          }else if(key === "BACK"){
            if (this.active_value.length > 0 && deleteChars == 0){
              this.active_value = this.active_value.slice(0, selectionStart - 1) + this.active_value.slice(selectionStart);
              this.$nextTick(() => {
                this.$refs[this._uid].setSelectionRange(selectionStart - 1, selectionStart - 1);
              });
            }
          }else{
            this.active_value = this.active_value.slice(0, selectionStart) + key + this.active_value.slice(selectionStart);
            this.$nextTick(() => {
              this.$refs[this._uid].setSelectionRange(selectionStart + 1, selectionStart + 1); 
            });
          }
        });
      },
      blur(e){
        this.$store.dispatch('postMessage', "keyboard:null");
        this.$store.commit('key_callback', null);
        this.$emit('blur', e.target.value)
      }
    }
  }
</script>

<template>
  <div class="user-input input">
    <input v-model="active_value" :class="{icon, 'password': type == 'password'}" :ref="customref || _uid" :type="type" :autocomplete="autocomplete" :placeholder="placeholder" :autofocus="autofocus" @focus="focus" @blur="blur" :min="type == 'number' ? 0 : null"/>
    <span v-if="icon" class="fa" :class="icon"></span>
  </div>
</template>

<style lang="scss" scoped>
  @import "~@/styles/constants";
  .user-input{
    position: relative;
    display: flex;
    align-items: center;
    input {
      flex: 1;
      max-width: 100%;
      box-sizing: border-box;
      height: 52px;
      padding: 0rem 1.5rem;
      font-size: 1.25rem;
      font-weight: 600;
      border-radius: 3px;
      border: 2px solid $input-border;
      box-shadow: $z_depth_0;
      color: $input;
      font-family: 'Montserrat', sans-serif;
      background-color: rgba(255,255,255,0.1);
      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $input-placeholder;
        opacity: 1; /* Firefox */
      }
      &::-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $input-placeholder;
      }
      &::-ms-input-placeholder { /* Microsoft Edge */
        color: $input-placeholder;
      }
      &:focus {
        border-color: $p;
        outline: 0;
      }
      &.icon{
        padding: 8px 36px 8px 16px;
      }
    }
    .fa{
      position: absolute;
      right: 16px;
    }
  }
</style>