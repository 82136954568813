import { make } from 'vuex-pathify'

import categories from "@/data/parts/categories.js";
import parts from "@/data/parts/parts.js";
import * as Dimensions from '@/lib/dimensions';
import * as Units from '@/lib/units';

const state = {
  categories,
  parts,
}

const getters = {
  ...make.getters([...Object.keys(state)]),
  parts: (state, getters, rootState) => {
    let parts = state.parts.filter(part => part.metric == rootState.useMetric)
    if(rootState.rail_height_filter){
      parts = parts.filter(part => rootState.rail_height_filter == part.details.width)
    }
    if(rootState.series_filter){
      parts = parts.filter(part => part.details.series.includes(rootState.series_filter))
    }
    if(rootState.profile_filter){
      parts = parts.filter(part => part.details.profile.includes(rootState.profile_filter))
    }
    if(rootState.construction_filter){
      parts = parts.filter(part => rootState.construction_filter == part.details.construction)
    }
    return parts
  },
  partsByCategory: (state, getters) => (category) => {
    return getters.parts.filter(part => part.categories.includes(category?.id)).filter(part => !part.hidden)
  },
  partsByCategoryUnfiltered: (state, getters, rootState) => (category) => {
    return state.parts.filter(part => part.metric == rootState.useMetric).filter(part => part.categories.includes(category?.id)).filter(part => !part.hidden)
  },
  getPartsCount: (state, getters) => (category) => {
    const filtered_count = getters.partsByCategory(category).length
    const unfiltered_count = getters.partsByCategoryUnfiltered(category).length
    if(unfiltered_count == 0){
      return "0"
    }else if(unfiltered_count == filtered_count){
      return unfiltered_count+"";
    }else{
      return filtered_count+"/"+unfiltered_count
    }
  },
  partById: (state) => (id) => {
    return state.parts.find(part => part.id == id)
  },
  categoryById: (state) => (id) => {
    return state.categories.find(category => {
      return category.id == id
    })
  },
  getPartByLedgerKey: (state, getters) => (ledgerKey) => {
    return getters.partById(Dimensions.getPartIdFromLedgerKey(ledgerKey));
  },
  getPartPriceByLedgerKey: (state, getters) => ({ledgerKey, price, quantity}) => {
    let value = 0;
    if(price){
      if(String(price).match(/[a-zA-Z]+/g)){
        if(String(price).includes("sqft") && Dimensions.getCustomLength(ledgerKey) && Dimensions.getCustomWidth(ledgerKey)){
          const area = Dimensions.getCustomLength(ledgerKey) * Dimensions.getCustomWidth(ledgerKey);
          value = Units.convertValueToSquareMeters(price) * area * quantity;
        }else if(String(price).includes("in") && Dimensions.getCustomLength(ledgerKey)){
          value = Units.convertValueToMeters(price) * parseFloat(Dimensions.getCustomLength(ledgerKey)) * quantity;
        }else{
          value = parseFloat(Units.getNumber(price)) * quantity  
        }
      }else{
        value = parseFloat(Units.getNumber(price)) * quantity
      }
    }
    
    return Math.round(value * 100) / 100;
  },
  getTotalFromLedger: (state, getters, rootState, rootGetters) => ({partLedger, saveFile}) => {
    let total = 0;
    const add = (parts) => {
      if(parts){
        Object.keys(parts).forEach(key => {
          const part = getters.getPartByLedgerKey(key);

          const vendor = getters.getVendorForPart(part);
          const quantity = saveFile.customQuantity && saveFile.customQuantity[key] ? saveFile.customQuantity[key] : parts[key].quantity;

          let price = 0;
          if(saveFile.customPrices && saveFile.customPrices[key]){
            price = saveFile.customPrices[key]
          }else if(vendor && vendor.price != null){
            price = vendor.price
          }

          const value = getters.getPartPriceByLedgerKey({
            ledgerKey: key, 
            price,
            quantity
          });

          total += Math.round(value * 100);

          if(vendor && vendor.surcharge){
            total += Math.round(parseFloat(Units.getNumber(vendor.surcharge)) * quantity * 100)
          }

          if(vendor && !vendor.fastenersIncluded && parts[key].parts){
            add(parts[key].parts);
          }
        })
      }
    }

    add(partLedger);
    return total / 100;
  },
  getVendorForPart: (state, getters, rootState, rootGetters) => (part) => {
    // if(part.vendors[rootGetters['selectedVendor']]){
    //   return part.vendors[rootGetters['selectedVendor']]
    // }else 
    if(part && part.vendors){
      if(Object.keys(part.vendors).length > 0){
        return part.vendors[Object.keys(part.vendors)[0]];
      }
    }
    return null
  },
  getPartCountFromLedger: (state, getters) => (partLedger) => {
    let count = 0
    Object.keys(partLedger).forEach((key) =>{
        count += partLedger[key].quantity
      })
    return count
    // return Object.keys(partLedger).reduce((acc, key) => acc + partLedger[key], 0);
  },
}

const mutations = {
  ...make.mutations([...Object.keys(state)]),
}

const actions = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}