<script>
import PartCard from "@/components/atoms/PartCard";
export default {
  components: { PartCard },
  computed: {
    part(){
      this.$gtag.event('view_part', {'event_category': 'flow', 'event_label': this.$route.params.part_id});
      return this.$store.get("parts/partById",this.$route.params.part_id)
    },
  },
  methods:{
    getForwardLink(relatedPartId){
      if(this.$route.params.part_id_1){
        return '/parts/categories/'+this.$route.params.part_category+'/.../'+this.$route.params.part_id+'/'+relatedPartId
      }else{
        return '/parts/categories/'+this.$route.params.part_category+'/'+this.$route.params.part_id+'/'+relatedPartId
      }
    },
    getBackwardLink(){
      if(this.$route.params.part_id_1){
        return '/parts/categories/'+this.$route.params.part_category+'/'+this.$route.params.part_id_1
      }else{
        return '/parts/categories/'+this.$route.params.part_category
      }
    },
    goBack(){
      this.$router.go(-1);
    },
    toTitleCase(value){
      var sentence = value.toLowerCase().split("_");
      for(var i = 0; i< sentence.length; i++){
         sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
      }
      return sentence.join(" ");
    }
  }
};
</script>


<template>
  <div class="part-details">
    <div class="title-row">
      <div class="part-name" @click="goBack()">
        <div class="back">
          <i class="fas fa-chevron-left fa-sm"></i> Back
        </div>
        <div class="name">
          {{part.name}} <span v-if="part.sub_name">{{part.sub_name}}</span>
        </div>
      </div>
    </div>
    <div class="part">
      <div class="part-area" :data-vr="part.id">
        <div class="grab-indicator" >
          <img class="grab" :src="'./icons/hand-sparkles-regular.svg'" />
          <div class="grab-text">Grab</div>
        </div>
        <div class="left-col">
          <div class="part-image" :style="part.image.style">
            <img :src="'./images/parts/'+part.image.name" />
            <span class="t-slot">
              <img v-for="series in part.details.series" :key="series" :src="'./icons/'+series+'W.svg'" />
            </span>
          </div>
        </div>
        <div class="right-col">
          <div class="details">
            <div v-for="(key, index) in Object.keys(part.details)" :key="index">
              <div class="detail" v-if="part.details[key] != null">
                <div class="label">
                  {{toTitleCase(key)}}
                </div>
                <div class="value" v-if="Array.isArray(part.details[key])">
                  {{part.details[key].reduce((acc, val, index) => index == part.details[key].length - 1 ? acc + val : acc + val + ", ", "")}}
                </div>
                <div class="value" v-else>
                  {{part.details[key]}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="vendors">
        <span v-for="(key, index) in Object.keys(part.vendors)" :key="index">
          <a v-for="(link, i) in part.vendors[key].links" :key="i" :href="link" target="_blank" @click="track(link)">{{part.vendors[key].name}}</a>
        </span>
      </div>
    </div>
    <div class="realted-parts">
      <div class="realted-parts-title">
        Related Parts
      </div>
      <div class="card-area">
        <PartCard
          v-for="(relatedPartId, index) in part.related_parts"
          :key="index"
          :part="$store.get('parts/partById', relatedPartId)"
          :link="getForwardLink(relatedPartId)"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/styles/constants";
.part-details { 
  .title-row{
    background-color: $black_2;
    padding: 1rem 1rem 0rem 1rem;
    .part-name { 
      display: inline-block;
      position: relative;
      padding: 1rem 1.5rem;
      border-radius: 0.5rem;
      &:hover, &.hover{
        background-color: $black_3;
        cursor: pointer;
        .back{
          top: 2rem;
          font-size: 2rem;
        }
        .name{
          opacity: 0;
        }
      }
      * {
        pointer-events: none;
      }
      .back{
        position: absolute;
        top: 1rem;
        left: 1.5rem;
        font-size: 1.25rem;
        padding-bottom: 0.5rem;
        display: flex;
        align-items: center;
        transition: all 0.3s ease;
        i{
          margin-right: 0.5rem;
        }
      }
      .name{
        padding-top: 2rem;
        font-size: 2rem;
        opacity: 1;
        transition: all 0.3s ease;
      }
    }
  }
	.part { 
    background-color: $black_2;
    padding: 0.5rem 1rem 0.5rem 1rem;
    .part-area{
      display: flex;
      align-items: flex-start;
      padding: 0.5rem 0.25rem 0.25rem 0.25rem;
      position: relative;
      &:hover, &.hover{
        .grab-indicator{
          // opacity: 1;
        }
      }
      * {
        pointer-events: none;
      }
    }
    .grab-indicator{
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      pointer-events: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: rgba(178, 99, 247,0.4);
      z-index: 1;
      opacity: 0;
      transition: all 0.3s ease;
      border-radius: 0.5rem;
      .grab{
        width: 8rem;
      }
      .grab-text{
        margin-top: 1rem;
        font-size: 3.5rem;
      }
    }
		.left-col { 
      padding: 0rem 0rem;
			.part-image { 
        width: 24rem;
        height: 17rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 1.5rem;
        margin-bottom: 1rem;
        position: relative;
				img { 
          max-width: 100%;
          max-height: 100%
				}
        .t-slot{
          position: absolute;
          bottom: 0rem;
          left: 0rem;
          height: 2.5rem;
          width: 100%;
          display: flex;
          align-items: center;
          img{
            margin-right: 0.75rem;
          }
        }
        
			}
		}

		.right-col { 
			.details { 
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 1rem 0rem 0rem 3rem;
				.detail { 
          margin: 0rem 3rem 2rem 0rem;
					.label { 
            font-size: 1.25rem;
            margin-bottom: 0.5rem;
            color: #CCC;
					}
					.value { 
            font-size: 1.5rem;
            color: #FFF;
					}
				}
			}
		}
	}
  .vendors{
    padding: 1rem;
    a{
      font-size: 1.25rem;
      color: #FFF;
      padding: 1rem;
    }
  }
  .realted-parts{
    padding: 2rem;
    .realted-parts-title{
      padding: 0rem 0.5rem;
      font-size: 1.5rem;
    }
    .card-area{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 1rem 0rem 2rem 0rem;
      margin-bottom: 2rem;
    }
  }
}
</style>