<script>
import Btn from "@/components/atoms/Btn";
import BtnRow from "@/components/atoms/BtnRow";
import Banner from "@/components/atoms/Banner";
import PartRow from "@/components/atoms/PartRow";
import vendors from "@/data/parts/vendors.js";
import In from "@/components/atoms/In.vue";

export default {
  components: {Banner, Btn, BtnRow, PartRow, In},
  props: ['showOptions'], 
  mounted(){
    this.getImageURL();

    const autocomplete = new google.maps.places.Autocomplete(this.$refs["origin"].$refs["origin"]);
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      this.address = place.formatted_address
    })

    this.email = this.$store.get('auth/user').email;
  },
  data(){
    return {
      url: null,
      first_name: null,
      last_name: null,
      phone_number: null,
      email: null,
      address: null,
      error: null,
      checkout: false,
      invoice_sent: false
    }
  },
  watch: {
    saveFile(){
      this.getImageURL();
    }
  },
  computed: {
    vendors(){
      return vendors;
    },
    saveFile(){
      return this.$store.get('saveFiles/saveFilesById', this.$route.params.project_id)
    },
    partLedger(){
      return this.$store.get('saveFiles/getPartLedger', this.$route.params.project_id)
    },
    total(){
      return this.$store.get('parts/getTotalFromLedger', {partLedger: this.partLedger, saveFile: this.saveFile})
    }
  },
  methods: {
    getImageURL(){
      if(this.saveFile){
        this.$store.dispatch("saveFiles/getImage", this.saveFile.imageName).then(url => this.url = url);
      }
    },
    blur(value){
      if(!value){
        this.$refs['origin'].$refs['origin'].value = null
      }else{
        this.$refs['origin'].$refs['origin'].value = this.address
      }
    },
    validate(){
      if(!this.first_name || !this.last_name || !this.phone_number || !this.address){
        this.error = "*Missing required fields"
      }else{
        this.$store.dispatch("payment/requestInvoice", {userID: this.$store.get('auth/user').uid, email: this.email, phone: this.phone, firstName: this.first_name, last_name: this.last_name, address: this.address, projectId: this.$route.params.project_id, saveFile: this.saveFile, total: this.total}).then(response => {
          this.error = null;
          this.invoice_sent = true;
          setTimeout(()=>{
            this.invoice_sent = false;
            this.checkout = false;
          }, 4000)
        }).catch(response => {
          console.log(response)
        })
      }
    }
  }
  
}
</script>

<template>
  <Banner>
    <div class="project">
      <transition name="fade-loading">
        <div class="overlay" v-if="invoice_sent" >
          <div class="module saved">
            <i class="fas fa-paper-plane"></i>
            <div class="saved">
              Request Sent
            </div>
            <div class="sub-text">
              Check your email. You should receive and invoice within the next 24 hours.
            </div>
          </div>
        </div>
      </transition>
      <div class="project-module">
        <div class="back-row">
          <btn size="large" color="secondary" @click="$router.push('/projects')"><i class="fas fa-caret-left"></i> My Projects</btn>
        </div>
        <div class="main-row" v-if="saveFile">
          <div class="image-col">
            <img class="image" :src="url" />
          </div>
          <div class="detail-col">
            <div class="project-title">
              {{saveFile.name}}
            </div>
            <div class="project-details">
              <div class="detail">
                <div class="label">
                  Last Updated
                </div>
                <div class="value">
                  {{saveFile.time}}
                </div>
              </div>
            </div>
            <div class="btn-container" v-if="$store.get('vr')">
              <btn type="fill" stretch="full" size="large" @click="$store.dispatch('saveFiles/loadFromCloud', saveFile)">Load Project</btn>
            </div>
            <!-- <div class="btn-container">
              <btn type="fill" color="secondary" >Share Project</btn>
              <btn type="fill" color="secondary">Share List</btn>
            </div> -->
          </div>
        </div>
        <!-- <div class="vendor-select">
          <div v-for="(vendor, index) in Object.keys(vendors)" :key="index" class="vendor" :class="{selected: $store.state.selectedVendor == vendor}" @click="$store.commit('selectedVendor', vendor)">
            {{vendors[vendor].name}}
          </div>
        </div> -->
        <div class="parts-row" v-if="saveFile">
          <div class="parts-table">
            <div class="headers row">
              <div class="item-col">Items</div>
              <div class="quantity-col">Qty</div>
              <div class="total-price-col">est. Price</div>
              <div class="price-col">est. Total</div>
            </div>
            <PartRow v-for="(ledgerKey, index) in Object.keys(partLedger)" :key="index" :ledger-key="ledgerKey" :save-file="saveFile" :ledgerPart="partLedger[ledgerKey]" :index="index" ref="part" :number="index"></PartRow>
          </div>
          <div class="total">
            <span class="text">est. Total </span><span class="price">${{total}}</span>
          </div>
        </div>
        <div class="checkout">
          <div class="purchase" v-show="!checkout">
            <btn type="fill" @click="checkout = true">Checkout</btn>
          </div>
          <div class="checkout-module" v-show="checkout">
            <div class="input-row">
              <in v-model="first_name" placeholder="First Name"></in>
              <in v-model="last_name" placeholder="Last Name"></in>
            </div>
            <div class="input-row">
              <in v-model="phone_number" placeholder="Phone Number"></in>
              <in v-model="email" placeholder="Email"></in>
            </div>
            <div class="input-row">
              <in placeholder="Shipping Address" customref="origin" ref="origin" @blur="blur"></in>
            </div>
            <div class="error-row" v-if="error">
              {{error}}
            </div>
            <div class="button-row">
              <btn type="fill" stretch="full" @click="validate">Request Invoice</btn>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </Banner>
</template>

<style lang="scss">
@import "~@/styles/constants";
.project{
  display: flex;
  align-items: center;
  justify-content: center;
  .project-module{
    flex-basis: 80rem;
    padding: 1rem;
    .back-row{
      margin-bottom: 2rem;
      .button{
        font-size: 1.5rem;
      }
      i{
        padding-right: 0.5rem;
      }
    }
    .main-row{
      display: flex;
      align-items: flex-start;
      margin-bottom: 3rem;
      .image-col{
        width: 50%;
        img{
          width: 100%;
          filter: brightness(2.5);
        }
      }
      .detail-col{
        width: 50%;
        padding-left: 4rem;
        .project-title{
          font-size: 2rem;
          margin-bottom: 1rem;
        }
        .project-details { 
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          .detail { 
            margin: 0rem 3rem 2rem 0rem;
            .label { 
              font-size: 1.25rem;
              margin-bottom: 0.5rem;
              color: #CCC;
            }
            .value { 
              font-size: 1.5rem;
              color: #FFF;
            }
          }
        }
        .btn-container{
          display: flex;
          align-items: center;
          justify-content: stretch;
          margin-top: 1rem;
          .button{
            flex: 1;
            &:nth-child(2) {
              margin-left: 1rem;
            }
          }
        }
      }
    }
    .total{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 1.5rem;
      padding: 1.5rem 2rem;
      .text{
        margin-right: 1rem;
        color: $w_1;
      }
      .price{
        
      }
    }
    .checkout{
      width: 100%;
      z-index: 100;
      margin-bottom: 6rem;
      .purchase{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .button{
          width: 20rem;
        }
      }
      .checkout-module{
        flex-basis: 80rem;
        padding: 1rem;
        .input-row{
          display: flex;
          align-items: center;
          .input{
            padding: 0.5rem;
            flex: 1;
          }
        }
        .error-row{
          color: $error;
          padding: 0.5rem;
        }
        .button-row{
          padding: 0.5rem;
        }
      }
    }
    .vendor-select{
      display: flex;
      align-items: center;
      .vendor{
        padding: 0.5rem;
        margin-right: 1rem;
        font-size: 1.5rem;
        color: $w_1;
        border-bottom: 0.25rem solid $black_1;
        &.selected{
          color: $w;
          border-color: $w;
        }
        &:hover, &.hover{
          cursor: pointer;
        }
      }
    }
    .parts-table{
      .item-row{
        display: flex;
        align-items: center;
        padding: 1.75rem 2rem;
        &:nth-child(even) {
          .row{
            // background-color: $black_2;
          }
        }
        .index{
          color: $w_1;
          width: 2rem;
          font-size: 1.25rem;
        }
      }
      .row{
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 1.125rem;
        // font-weight: 500;
        // background-color: $black_2;
        &.headers{
          color: $w_1;
          background-color: $black_1;
          min-height: 4rem;
          padding: 1.75rem 2rem;
        }
        
        .item-col{
          flex: 1;
          display: flex;
          align-items: center;
          .image-col{
            width: 8rem;
            min-width: 8rem;
            height: 5rem;
            min-height: 5rem;
            margin-right: 2rem;
            // padding: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              max-width: 100%;
              max-height: 100%;
            }
          }
          .name-col{
            margin-right: 1rem;
            .sizes{
              display: flex;
              align-items: center;
              flex-wrap: wrap;
            }
          }
          .distributors{
            padding-top: 0.5rem;
            a{
              font-size: 1.125rem;
              padding: 0.5rem 0.5rem 0rem 0rem;
              color: #FFF;
            }
          }
        }
        .quantity-col{
          width: 5rem;
          text-align: right;
        }
        .price-col{
          width: 10rem;
          text-align: right;
        }
        .total-price-col{
          width: 10rem;
          text-align: right;
        }
        // .seller-col{
        //   flex: 1;
        //   display: flex;
        //   align-items: center;
        //   flex-wrap: wrap;
        //   a{
        //     padding: 0.5rem;
        //     color: #FFF;
        //   }
        // }
      }
    }
  }
  .overlay{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    .module{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 5rem 6rem;
      border-radius: 0.5rem;
      &.saving{
        background-color: #444;
      }
      &.saved{
        background-color: #48c586;
      }
      .press{
        width: 15rem;
        margin-bottom: 4rem;
      }
      .text{
        font-size: 2rem;
        margin-bottom: 1.5rem;
      }
      .sub-text{
        font-size: 1.25rem;
        max-width: 30rem;
        text-align: center;
      }
      .fas{
        font-size: 3rem;
        margin-bottom: 1.5rem;
        // color: #64f7ad;
      }
      .saved{
        font-size: 2rem;
        margin-bottom: 0.5rem;
        // color: #64f7ad;
      }
    }
  }
  .fade-loading-enter-active{
    transition: all 0.4s;
  }
  .fade-loading-leave-active{
    transition: all 0.2s;
  }
  .fade-loading-enter{
    opacity: 0;
  }
  .fade-loading-leave-to{
    opacity: 0;
  }
}
</style>