<script>
import Drawer from "@/components/atoms/Drawer";
import RailHeightFilterButton from "@/components/atoms/RailHeightFilterButton";
import TSlotFilterButton from "@/components/atoms/TSlotFilterButton";
import ProfileFilterButton from "@/components/atoms/ProfileFilterButton";
import ConstructionFilterButton from "@/components/atoms/ConstructionFilterButton";
import ButtonToggle from "@/components/atoms/ButtonToggle";

export default {
  components: { Drawer, RailHeightFilterButton, TSlotFilterButton, ProfileFilterButton, ConstructionFilterButton, ButtonToggle },
   methods: {
    hasPath(path_name){
      return !!this.$router.currentRoute.matched.find(route => route.name == path_name)
    },
    onClick(path){
      this.$router.push(path);
    }
  }
};
</script>


<template>
  <div id="parts-page">
    <Drawer>
      <template v-slot:drawer> 
        <div class="parts-drawer">
          <div class="filters">
            <div class="filters-title">
              Compatibility
              <div v-if="$store.get('filterCount') > 0" class="filter-count" @click="$store.set('clearFilters')"><span class="fal fa-times"></span>Clear</div>
            </div>
            <div class="filter">
              <div class="filter-title">
                Series
              </div>
              <div class="filter-button-group">
                <template v-if="$store.get('useMetric') != true">
                  <TSlotFilterButton value="10" />
                  <TSlotFilterButton value="15" />
                </template>
                <template v-if="$store.get('useMetric') == true">
                  <TSlotFilterButton value="20" />
                  <TSlotFilterButton value="30" />
                  <TSlotFilterButton value="40" />
                  <TSlotFilterButton value="45" />
                </template>
              </div>
            </div>
            <div class="filter">
              <div class="filter-title">
                Rail Height
              </div>
              <div class="filter-button-group">
                <template v-if="$store.get('useMetric') != true">
                  <RailHeightFilterButton value='1"' />
                  <RailHeightFilterButton value='1.5"' />
                  <RailHeightFilterButton value='2"' />
                  <RailHeightFilterButton value='3"' />
                  <RailHeightFilterButton value='4"' />
                  <RailHeightFilterButton value='4.5"' />
                </template>
                <template v-if="$store.get('useMetric') == true">
                  <RailHeightFilterButton value='20mm' />
                  <RailHeightFilterButton value='30mm' />
                  <RailHeightFilterButton value='40mm' />
                  <RailHeightFilterButton value='45mm' />
                  <RailHeightFilterButton value='60mm' />
                  <RailHeightFilterButton value='80mm' />
                  <RailHeightFilterButton value='90mm' />
                  <RailHeightFilterButton value='120mm' />
                  <RailHeightFilterButton value='160mm' />
                </template>
              </div>
            </div>
            <div class="filter">
              <div class="filter-title">
                Profiles
              </div>
              <div class="filter-icon-group">
                <ProfileFilterButton value="Single" width="2rem"/>
                <ProfileFilterButton value="Double" width="4rem"/>
                <ProfileFilterButton value="Quad" width="4rem"/>
                <ProfileFilterButton value="Multi" width="6rem"/>
              </div>
            </div>
            <div class="filter">
              <div class="filter-title">
                Construction
              </div>
              <div class="filter-icon-group">
                <ConstructionFilterButton value="Solid" />
                <ConstructionFilterButton value="Light-Weight" />
                <ConstructionFilterButton value="Ultra-Light-Weight" />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:page>
        <div class="bread-crumbs" :class="{details: $route.params.part_id}">
          <template  v-if="hasPath('Parts')">
            <span class="link" :class="{active: $router.currentRoute.name == 'PartCategories'}" @click="() => onClick('/parts/categories')">
              All Parts
            </span>
            <template  v-if="$route.params.part_category">
              <span class="divider">/</span>
              <span class="link" :class="{active: $router.currentRoute.name == 'PartCategory'}" @click="() => onClick('/parts/categories/'+$route.params.part_category)">
                {{$store.get("parts/categoryById", $route.params.part_category).name}}
              </span>
            </template >
            <template v-if="$route.path.includes('...')">
              <span class="divider">/&nbsp;&nbsp;...&nbsp;&nbsp;</span>
            </template>
            <template v-if="$route.params.part_id_1">
              <span class="divider">/</span>
              <span class="link" :class="{active: $router.currentRoute.name == 'PartDetails'}" @click="() => onClick('/parts/categories/'+$route.params.part_category+'/'+$route.params.part_id_1)">
                {{$store.get("parts/partById", $route.params.part_id_1).name}}
              </span>
            </template >
            <template  v-if="$route.params.part_id">
              <span class="divider">/</span>
              <span class="link" :class="{active: true}">
                {{$store.get("parts/partById", $route.params.part_id).name}}
              </span>
            </template >
          </template >
        </div>
        <router-view></router-view>
      </template>
    </Drawer>
  </div>
</template>

<style lang="scss">
@import "~@/styles/constants";
#parts-page {
  height: 100%;
  position: relative;
  .parts-drawer{
    .filters{
      padding-bottom: 4rem;
      .filters-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2rem 2rem 0rem 2rem;
        font-size: 1.5rem;
        height: 4rem;
        .filter-count{
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.25rem;
          font-weight: 400;
          border-radius: 0.5rem;
          border: 1px solid #FFF;
          padding: 0.5rem 1rem;
          &:hover, &.hover{
            background-color: $black_4;
            cursor: pointer;
          }
          * {
            pointer-events: none;
          }
          .fal{
            margin-right: 0.5rem;
          }
        }
      }
      .toggle{
        margin: 1rem 2rem 0rem 2rem;
      }
      .filter{
        padding: 2rem 1.5rem 0rem 1.5rem;
        .filter-title{
          font-size: 1.25rem;
          padding: 0.5rem 1rem;
          color: #DDD;
        }
        .filter-button-group{
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .filter-button{
            font-size: 1.25rem;
            color: #FFF;
            padding: 1rem 1.5rem;
            margin: 0.125rem;
            border-radius: 0.25rem;
            &:hover, &.hover{
              background-color: $black_4;
            }
            * {
              pointer-events: none;
            }
            &.active{
              background-color: $p;
            }
            &.small{
              padding: 0.75rem 1.5rem;
            }
          }
        }
        .filter-icon-group{
          .filter-icon{
            color: #FFF;
            padding: 1rem 2rem;
            border-radius: 0.25rem;
            display: flex;
            align-items: center;
            &:hover, &.hover{
              background-color: $black_4;
            }
            * {
              pointer-events: none;
            }
            &.active{
              background-color: $p;
            }
            .icon-label{
              font-size: 1.25rem;
              padding: 0rem 1.5rem;
            }
          }
        }
      }
    }
  }
  .bread-crumbs{
    display: flex;
    align-items: center;
    padding: 1rem 2rem 0rem 2rem;
    font-size: 1.25rem;
    &.details{
      background-color: $black_2;
    }
    .divider{
      color: #AAA;
    }
    .link{
      margin: 0rem 0.5rem;
      padding: 0.5rem 0.5rem;
      color: #FFF;
      
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &.active{
        color: #AAA;
      }
      &:not(.active){
        &:hover, &.hover{
          background-color: $black_3;
          cursor: pointer;
          border-radius: 0.5rem;
        }
      }
      * {
        pointer-events: none;
      }
    }
  }
}
</style>

