<script>
  export default{
    props: [ 
      'color', // secondary
      'type', // 'fill', 'outline',
      'size',  // 'small' large
      'stretch', // 'full'
    ],
  }
</script>

<template>
  <div class="button" :class="[type, size, stretch, color]" @click="$emit('click', $event)">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
  @import "~@/styles/constants";
  .button{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    color: $button-primary;
    border-radius: 3px;
    white-space: nowrap;
    position: relative;
    &.secondary{
      color: #FFF;
      &:hover, &.hover{
        background-color: $button-secondary-hover;
      }
      &:active{
        background-color: $button-secondary-active;
      }
      &.fill{
        background-color: $button-secondary;
        &:hover, &.hover{
          background-color: $button-secondary-hover;
        }
        &:active{
          background-color: $button-secondary-active;
        }
      }
    }
    &:hover, &.hover{
      cursor: pointer;
      background-color: $button-secondary-active;
    }
    * {
      pointer-events: none;
    }
    &:active{
      background-color: $button-primary-active;
    }
    &.full{
      display: flex;
    }
    &.large{
      min-height: 52px;
    }
    &.small{
      min-height: 15px;
      padding: 4px 12px;
      font-size: 1rem;
    }
    &.fill{
      background-color: $button-primary;
      color: $w;
      box-shadow: $z_depth_1;
      &:hover, &.hover{
        background-color: $button-primary-hover;
      }
      &:active{
        background-color: $button-primary-active;
        box-shadow: $z_depth_0;
      }
      &.alt{
        background-color: $button-alt;
        color: $w;
        &:hover, &.hover{
          background-color: $button-alt-hover;
        }
        &:active{
          background-color: $button-alt-active;
        }
      }
    }
    &.outline{
      border: 2px solid $button-primary;
      &.secondary{
        border: 2px solid #FFF;
      }
    }
    &.back{
      color: #FFF;
      &:hover, &.hover{
        background-color: rgba(0,0,0,0.1)
      }
    }
    .fa{
      margin-right: 8px;
    }
    span{
      &:not(:first-child){
        margin-left: 6px;
      }
      &.fa-ellipsis-h{
        transform: scale(1.8);
      }
      &.fa-pen{
        // font-size: 10px;
      }
    }
    // .fa, .fal, .far{
    //   margin-right: 6px;
    // }
    input{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      margin: 0;
      padding: 0;
      font-size: 20rem;
      cursor: pointer;
      opacity: 0;
      filter: alpha(opacity=0);
      border-bottom: 0px solid #FFF;
    }
  }
</style>