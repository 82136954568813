<script>
import Card from "@/components/atoms/Card";
export default {
  components: { Card },
  props: ["part", "link"],
  computed: {
    vendor(){
      return this.$store.get('parts/getVendorForPart', this.part);
    },
    sub_value_left(){
      return this.vendor.name
    },
    vendorPrice(){
      return this.vendor ? this.vendor.price : "-";
    },
  }
};
</script>

<template>
  <Card
    :link="link"
    :image="'./images/parts/'+part.image.name"
    :icons="part.details.series ? part.details.series.map(series => './icons/'+series+'W.svg') : []"
    :image-style="part.image.style"
    :name="part.name" 
    :sub-name="part.sub_name"
    sub-title-left="Vendor"
    :sub-value-left="sub_value_left"
    sub-title-right="Appx Price"
    :sub-value-right="vendorPrice"
    :vr-id="part.id"
  >
  </Card>
</template>