<script>
import Btn from "@/components/atoms/Btn";
import BtnRow from "@/components/atoms/BtnRow";
export default {
  components: {Btn, BtnRow},
  props: ['saveFile', 'showOptions'], 
  mounted(){
    this.getImageURL();
  },
  data(){
    return {
      url: null
    }
  },
  computed: {
    imageName(){
      return this.saveFile.imageName;
    },
    partLedger(){
      return this.$store.get('saveFiles/getPartLedger', this.saveFile.id)
    },
    partCount(){
      return this.$store.get('parts/getPartCountFromLedger', this.partLedger)
    },
    total(){
      return this.$store.get('parts/getTotalFromLedger', {partLedger: this.partLedger, saveFile: this.saveFile})
    }
  },
  watch: {
    imageName(){
      this.getImageURL();
    }
  },
  methods: {
    getImageURL(){
      if(this.saveFile){
        this.$store.dispatch("saveFiles/getImage", this.imageName).then(url => this.url = url);
      }
    }
  }
}
</script>

<template>
  <div class="save-file">
    <div class="existing-project card" @click="$emit('click', saveFile)">
      <div class="col left">
        <div class="image-container">
          <img class="image" :src="url" />
        </div>
      </div>
      <div class="col right">
        <div class="project-name">
          {{saveFile.name}}
        </div>
        <div class="row-container">
          <div class="row">
            <div class="detail">
              <div class="label">
                Last Modified
              </div>
              <div class="value">
                {{saveFile.time}}
              </div>
            </div>
            <!-- <div class="detail right">
              <div class="label">
                User
              </div>
              <div class="value">
                {{saveFile.userID}}
              </div>
            </div> -->
          </div>
          <div class="row">
            <div class="detail">
              <div class="label">
                Parts
              </div>
              <div class="value">
                {{partCount}}
              </div>
            </div>
            <div class="detail right">
              <div class="label">
                est. Total
              </div>
              <div class="value">
                ${{total}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showOptions" class="button-row">
      <btn type="fill" class="load" @click="$emit('load', saveFile)">Load Project</btn>
      <!-- <btn type="fill" class="purchase" color="secondary">Share</btn> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/styles/constants";
.save-file{
  .button-row{
    display: flex;
    padding: 0rem 2rem;
    margin-top: -1rem;
    .load{
      flex: 1;
      margin-right: 1rem;
    }
    .purchase{
      width: 16rem;
    }
  }
}
.card{
  width: 50rem;
  max-height: 16rem;
  min-height: 16rem;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  margin: 2rem;
  margin-top: 3rem;
  border-radius: 0.5rem;
  transition: transform 0.3s ease;
  box-shadow: $z_depth_2;
  background-color: $card_background;
  &.add-name{
    &:hover, &.hover{
      transform: scale(1);
    }
    * {
      pointer-events: auto;
    }
  }
  &:hover, &.hover{
    transform: scale(1.025);
    cursor: pointer;
  }
  * {
    pointer-events: none;
  }
  .col{
    &.left{
      padding: 1rem 0rem 1rem 1rem;
      .image-container{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 21rem;

        .image{
          width: 100%;
          filter: brightness(2.5);
        }
      }
    }
    &.right{
      flex: 1;
      display: flex;
      align-items: stretch;
      flex-direction: column;
      padding: 2.5rem;
      .project-name{
        // margin-bottom: 1rem;
        font-size: 1.25rem;
      }
      .row-container{
        flex: 1;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        flex-direction: column;
      }
      .row{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .detail{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        margin-top: 1rem;
        &.right{
          align-items: flex-end;
        }
        .label{
          color: $w_1;
          font-size: 1rem;
        }
        .value{
          font-size: 1.25rem;
        }
      }
    }
  }
}
</style>