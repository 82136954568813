<script>
export default {
  props: [
    "link",
    "image",
    "icons",
    "imageStyle",
    "name",
    "subName",
    "subTitleLeft",
    "subValueLeft",
    "subTitleRight",
    "subValueRight",
    "vrId"
  ],
  methods: {
    onClick(){
      if(this.link){
        this.$router.push(this.link);
      }
    }
  }
};
</script>

<template>
  <div class="card" @click="onClick" :data-vr="vrId">
    <div class="grab-indicator">
      <img class="grab" :src="'./icons/hand-sparkles-regular.svg'" />
      <div class="grab-text">Grab</div>
    </div>
    <div class="image-container" :style="imageStyle">
      <img class="image" :src="image" />
      <span class="icons">
        <img v-for="icon in icons" :key="icon" class="icon" :src="icon" />
      </span> 
    </div>
    <div class="description-container">
      <div class="name">
        {{ name }}
        <span v-if="subName" class="sub-name"><br>{{subName}}</span>
      </div>
      <div class="sub-title">
        <div class="left">
          <div class="title">
            {{ subTitleLeft }}
          </div>
          <div class="value">
            {{ subValueLeft }}
          </div>
        </div>
        <div class="right">
          <div class="title">
            {{ subTitleRight }}
          </div>
          <div class="value">
            {{ subValueRight }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/styles/constants";
.card {
  width: 24rem;
  height: 26rem;
  min-width: 24rem;
  min-height: 26rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: $card_background;
  box-shadow: $z_depth_2;
  transition: transform 0.3s ease;
  border-radius: 0.5rem;
  position: relative;
  &:hover, &.hover{
    transform: scale(1.025);
    cursor: pointer;
    .grab-indicator{
      // opacity: 1;
    }
  }
  * {
    pointer-events: none;
  }
  .grab-indicator{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(178, 99, 247,0.4);
    z-index: 1;
    opacity: 0;
    transition: all 0.3s ease;
    .grab{
      width: 8rem;
    }
    .grab-text{
      margin-top: 1rem;
      font-size: 3.5rem;
    }
  }
  .image-container {
    height: 65%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .image {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
    .icons{
      position: absolute;
      bottom: 0rem;
      left: 1.6rem;
      width: 8rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      .icon{
        height: 100%;
        margin-right: 0.75rem;
      }
    }
  }
  .description-container {
    flex: 1;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    .name {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      text-overflow: ellipsis;
      .sub-name{
        font-size: 1.125rem;
      }
    }
    .sub-title {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .title {
        color: $w_1;
        font-size: 1rem;
        margin-bottom: 0.25rem;
      }
      .value {
        font-size: 1.125rem;
      }
      .right{
        text-align: right;
      }
    }
  }
}
</style>