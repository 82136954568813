<script>
  import Btn from "@/components/atoms/Btn";
  import Banner from "@/components/atoms/Banner";
  export default {
    components: { Btn, Banner },
    created(){
      console.log(window.location.origin);
    },
    methods:{
      contact(){
        window.open('https://www.quadrobee.com/contact',"_self")
      }
    }
  }
</script>

<template>
  <Banner>
    <div id="canceled-page">
      <div class="main">
        Oh No!
      </div>
      <div class="title">
        Unable to process payment.
      </div>
      <div class="sub-title">
         Please contact support or try again later.
      </div>
      <div>
        <btn type="outline" color="secondary" @click="$router.push({name: 'Pricing'})">Go Back</btn>
        <btn type="outline" color="secondary" @click="contact()">Contact Support</btn>
      </div>
    </div>
  </Banner>
</template>

<style lang="scss">
@import "~@/styles/constants";
#canceled-page { 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $black_2;
  min-height: calc(100vh - 4rem);
  padding-bottom: 6rem;
  .main{
    font-size: 6rem;
    margin-bottom: 1rem;
  }
  .title{
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  .sub-title{
    font-size: 1.25rem; 
    margin-bottom: 2rem;
  }
  .button{
    margin: 0rem 0.5rem;
  }
}
</style>
