<script>
import * as Dimensions from '@/lib/dimensions';
import * as Units from '@/lib/units';
import In from "@/components/atoms/In";
export default {
  name: 'PartRow',
  components: {In},
  props: ["ledgerKey", "ledgerPart", "index", "saveFile", "number"],
  created(){
    if(this.saveFile.customNames && this.saveFile.customNames[this.ledgerKey]){
      this.customName = this.saveFile.customNames[this.ledgerKey];
    }
    if(this.saveFile.customPrices && this.saveFile.customPrices[this.ledgerKey]){
      this.customPrice = Units.getNumber(this.saveFile.customPrices[this.ledgerKey]);
    }
    if(this.saveFile.customQuantity && this.saveFile.customQuantity[this.ledgerKey]){
      this.customQuantity = Units.getNumber(this.saveFile.customQuantity[this.ledgerKey]);
    }
  },
  data(){
    return {
      customName: '',
      editCustomName: false,
      customPrice: '',
      customQuantity: '',
      editCustomQuantity: false,
    }
  },
  computed: {
    part(){
      return this.$store.get("parts/getPartByLedgerKey", this.ledgerKey);
    },
    useMetric(){
      return this.$store.get('useMetric')
    },
    width(){
      return Dimensions.getWidth(this.part, this.ledgerKey, this.useMetric)
    },
    height(){
      return Dimensions.getHeight(this.part, this.ledgerKey, this.useMetric)
    },
    length(){
      return Dimensions.getLength(this.part, this.ledgerKey, this.useMetric)
    },
    size(){
      return {
        ...(this.width ? {Width: this.width} : {}),
        ...(this.height ? {Height: this.height} : {}),
        ...(this.length ? {Length: this.length} : {}),
      }
    },
    vendor(){
      return this.$store.get('parts/getVendorForPart', this.part);
    },
    vendorPrice(){
      if(this.vendor && this.vendor.price){
        return this.vendor.price;
      }else{
        return "$0";
      }
    },
    price(){
      let total = this.$store.get("parts/getPartPriceByLedgerKey", {
        ledgerKey: this.ledgerKey, 
        price: this.customPrice === null || this.customPrice === '' ?  this.vendorPrice : this.customPrice, 
        quantity: this.customQuantity === null || this.customQuantity === '' ? this.ledgerPart.quantity : this.customQuantity});

      if(this.vendor && this.vendor.surcharge){
        total += parseFloat(Units.getNumber(this.vendor.surcharge)) * (this.customQuantity !== null ? this.customQuantity : this.ledgerPart.quantity)
      }
      return Math.round(total * 100) / 100;
    },
    taps(){
      return Dimensions.getTaps(this.ledgerKey);
    },    
    name(){
      if(this.part.sub_name){
        return this.part.name+' '+this.part.sub_name
      }else{
        return this.part.name
      }
    }
  },
  methods: {
    track(link){
      this.$gtag.event('view_item', {'event_label': link})
    },
    getNumber(value){
      return Units.getNumber(value)
    },
    updateCustomName(){
      this.$store.dispatch("saveFiles/updateCustomField", {
        saveFile: this.saveFile, 
        ledgerKey: this.ledgerKey, 
        field: 'customNames', 
        value: this.customName
      })
    },
    updateCustomPrice(){
      this.$store.dispatch("saveFiles/updateCustomField", {
        saveFile: this.saveFile, 
        ledgerKey: this.ledgerKey, 
        field: 'customPrices', 
        value: '$'+this.customPrice
      })
    },
    updateCustomQuantity(){
      this.$store.dispatch("saveFiles/updateCustomField", {
        saveFile: this.saveFile, 
        ledgerKey: this.ledgerKey, 
        field: 'customQuantity', 
        value: this.customQuantity
      })
    }
  }
}
</script>


<template>
  <div>
    <div class="item-row">
      <div class="index">
        <span v-if="number !== undefined">
          {{number + 1}}.
        </span>
      </div>
      <div class="row part-row">
        <div class="item-col" v-if="part">
          <div class="image-col" @click="$router.push({ path: '/parts/'+part.id })">
            <img :src="'./images/parts/'+part.image.name" />
          </div>
          <div class="name-col">
            <!-- <div v-if="editCustomName && Object.keys(size).length == 3">
              <in v-model="customName" :placeholder="name" :autofocus="true" @blur="() => {editCustomName = false; updateCustomName()}"></in>
            </div> -->
            <div class="name" @click="$router.push({ path: '/parts/'+part.id })">
              <template v-if="customName == null || customName == ''">
                {{name}}
              </template>
              <template v-else>
                {{customName}}
              </template>
            </div>
            <div class="sizes" v-if="Object.keys(size).length > 0">
              <span class="size" v-for="(key, index) in Object.keys(size)" :key="index">
                <span class="name">{{key}}:</span>
                <span class="value" v-html="size[key]"></span>
              </span>
            </div>
            <div class="sizes" >
              <span v-for="(tap, index) in taps" :key="index" class="size">
                <span class="name" v-if="tap.includes(' x ')">Required end taps:</span>
                <span class="name" v-else>Required end tap:</span>
                <span class="value">{{tap}}</span>
              </span>
            </div>
            <div class="distributors">
              <template v-if="vendor">
                <a v-for="(link, index) in vendor.links" :key="index" :href="link" target="_blank" @click="track(link)">{{vendor.name}}</a>
              </template> 
            </div>
          </div>
        </div>
        <div v-else class="item-col">
          Missing: {{ledgerKey ? ledgerKey.split("::")[0] : "unknown"}}
        </div>
        <!-- v-if="editCustomQuantity || customQuantity !== ''" -->
        <div class="quantity-col">
          <in v-model="customQuantity" :placeholder="ledgerPart.quantity" :autofocus="editCustomQuantity" type="number" @blur="() => {editCustomQuantity = customQuantity !== ''; updateCustomQuantity()}"></in>
        </div>
        <!-- <div v-else @click="() => editCustomQuantity = true" class="quantity-col">
          {{ledgerPart.quantity}}
        </div> -->
        <div class="price-col">
          <template v-if="part">
            <template v-if="!vendor || !vendor.price">
              <div class="price-row">
                <span style="margin-right: 0.25rem;">$</span> <in v-model="customPrice" placeholder="0" :autofocus="false" type="number" @blur="updateCustomPrice()"></in>
              </div>
            </template>
            <template v-else>
              <div class="price-row">
                <div>
                  <div>{{vendor.price}}</div>
                  <div v-if="vendor.surcharge" class="surcharge">
                    {{vendor.surcharge}} surcharge
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
        <div class="total-price-col">
          <template v-if="part">
            ${{price}}
          </template>
        </div>
      </div>
    </div>
    <template v-if="vendor && !vendor.fastenersIncluded">
      <PartRow v-for="(childLedgerKey, index) in Object.keys(ledgerPart.parts)" :key="index" :ledger-key="childLedgerKey" :save-file="saveFile" :ledgerPart="ledgerPart.parts[childLedgerKey]" :index="index" ref="part"></PartRow>
    </template>
  </div>
</template>

<style lang="scss">
  @import "~@/styles/constants";
  .part-row{
    .item-col{
      .input{
        input{
          height: 2rem;
          padding: 0rem 0.75rem;
        }
      }
    }
    .name-col{
      min-height: 28px;
      flex: 1;
      .input{
        margin: -0.357rem -0.875rem;
        input{
          font-size: 1.125rem;
          width: 100%;
        }
      }
      .fa-pen{
        font-size: 0.8rem; 
        color: $w_1;
      }
    }
    .image-col, .name{
      &:hover, &.hover{
        cursor: pointer;
      }
    }
    .sizes{
      padding-top: 0.5rem;
      .size{
        margin-right: 1rem;;
        font-size: 1.125rem;
        font-weight: 500;
        // color: $y;
        .name{
          color: $w_1;
          margin-right: 0.5rem;
        }
        .value{
          .unit{
            // font-size: 0.875rem;
          }
        }
      }
    }
    .taps{
      padding-top: 0.5rem;
      .tap{
        margin-right: 1rem;;
        font-size: 1.125rem;
        font-weight: 500;
        // color: $y;
        .name{
          margin-right: 0.5rem;
        }
        .value{
        }
      }
    }
    .quantity-col{
      &:hover, .hover{
        cursor: pointer;
      }
      .input{
          margin: -0.357rem 0rem;
          input{
            width: 6rem;
            height: 2rem;
            padding: 0rem 0rem 0rem 0.75rem;
          }
        }
    }
    .price-col{
      .price-row{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        .surcharge{
          color: $w_1;
          font-size: 0.825rem;
        }
        .input{
          margin: -0.357rem 0rem;
          input{
            width: 6rem;
            height: 2rem;
            padding: 0rem 0rem 0rem 0.75rem;
          }
        }
        .fa-pen{
          opacity: 0;
          font-size: 0.875rem;
          color: $w_1;
          margin-left: 0.25rem;
          margin-right: -1.125rem;
        }
        &:hover, .hover{
          cursor: pointer;
          .fa-pen{
            opacity: 1;
          }
        }
      }
    }
  }
</style>