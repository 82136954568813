<script>
export default {
  props: ["title", "detail", "link", "back"],
  methods: {
    onClick(){
      if(this.link){
        this.$router.push(this.link)
      }
    }
  }
};
</script>


<template>
  <div class="card-section" :class="{limited: !!link}">
    <div class="section-title-area" @click="onClick">
      <div class="section-title">
        <i v-if="back" class="fas fa-chevron-left fa-sm"></i>
        {{ title }}
      </div>
      <div class="view-all" v-if="detail">{{detail}}</div>
    </div>
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/styles/constants";
.card-section {
  &.limited{
    .section-title-area {
      &:hover, &.hover {
        background-color: $black_2;
      }
      * {
        pointer-events: none;
      }
    }  
  }
  .section-title-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 3rem;
    .section-title {
      font-size: 2rem;
      i{
        margin-right: 0.5rem;
      }
    }
    .view-all {
      font-size: 1.25rem;
    }
  }
}
</style>