<script>
import Vue from 'vue'
export default {
  props: ['left', 'right', 'default'],
  data() {
    return {
      selected: this.default ? this.default : this.left,
    }
  },
  watch: {
    default(){
      this.selected = this.default;
    }
  },
  methods: {
    select(value) {
      this.selected = value;
      this.$emit('select', value)
    }
  }
};
</script>

<template>
  <div class="switch">
    <div class="left button" @click="select(left)" :class="{active: selected == left}">
      {{left}}
    </div>
    <div class="right button" @click="select(right)" :class="{active: selected == right}">
      {{right}}
    </div>
  </div>
</template>

<style lang="scss">
@import "~@/styles/constants";
.switch{
  display: flex;
  align-items: center;
  .button{
    padding: 0.75rem 1rem;
    font-size: 1.25rem;
    // background-color: $black_2;
    transition: all 0.3s ease;
    &:hover, &.hover{
      cursor: pointer;
      background-color: $black_4;
    }
    * {
      pointer-events: none;
    }
    &.active{
      background-color: $p;
      &:hover, &.hover{
        background-color: $p;
      }
    }
    &.left{
      border-radius: 0.5rem;
    }
    &.right{
      margin-left: 0.25rem;
      border-radius: 0.5rem;
    }
  }
}

</style>