<script>
  import In from "@/components/atoms/In";
  import Btn from "@/components/atoms/Btn";
  import Banner from "@/components/atoms/Banner";
  import Loading from "@/components/atoms/Loading";
  import SelectOption from '@/components/atoms/SelectOption.vue'
  export default {
    components: { In, Btn, Banner, Loading, SelectOption },
    data(){
      return {
        billing_options: [
          {
            display: 'Monthly',
            value: 'Monthly'
          },{
            display: 'Yearly - 2 Months Free',
            value: 'Yearly'
          }
        ],
        main_billing: 'Monthly',
        special_billing: 'Monthly',
      }
    },
    methods: {
      mailTo(){
        window.open('mailto:sales@quadrobee.com');
      },
      purchase(price){
        if(this.$store.get('auth/signed_in')){
          this.$store.dispatch('payment/subscribeUser', price)
        }else{
          this.$router.push('/signin?to='+this.$route.name)
        }
      }
    }
  }
</script>

<template>
  <Banner :show-footer="true">
    <div id="pricing-page">
      <Loading :loading="$store.get('payment/subscribingUser')" />
      <div class="pricing-container">
        <div class="pricing-sub-title">
          Free 30 day trial
        </div>
        <div class="pricing-title">
          Membership Pricing
        </div>
        <div class="plan-container">
          <div class="plan special">
            <div class="plan-title">
              EARLY ADOPTER
            </div>
            <template v-if="special_billing == 'Yearly'">
              <div class="plan-price">
                <span class="value">$60</span><span class="interval"> / year</span>
              </div>
              <div class="per-user">
                per user
              </div>
            </template>
            <template v-else>
              <div class="plan-price">
                <span class="value">$6</span><span class="interval"> / month</span>
              </div>
              <div class="per-user">
                per user
              </div>
            </template>

            <select-option :options="billing_options" @monthly="special_billing = 'Monthly'" @yearly="special_billing = 'Yearly'" location="right">
              <btn type="outline" size="small">Billed: {{special_billing}} <span class="fa fa-caret-down"></span></btn>
            </select-option>

            <div class="plan-benefits">
              <div class="includes">Plan includes:</div>
              <div class="benefit">
                <span class="fa fa-check"/>Standard Parts
              </div>
              <div class="benefit">
                <span class="fa fa-check"/>Limited Support
              </div>
            </div>
            <div class="purchase-button" @click="purchase(special_billing == 'Yearly' ? 'price_1KajAHH6pG6cvCgpaWqxlI4Q' : 'price_1KajA6H6pG6cvCgprf9YELY7')">
              Purchase
            </div>
          </div>
          <div class="plan main">
            <div class="plan-title">
              STANDARD
            </div>
            <template v-if="main_billing == 'Yearly'">
              <div class="plan-price">
                <span class="value">$120</span><span class="interval"> / year</span>
              </div>
              <div class="per-user">
                per user
              </div>
            </template>
            <template v-else>
              <div class="plan-price">
                <span class="value">$12</span><span class="interval"> / month</span>
              </div>
              <div class="per-user">
                per user
              </div>
            </template>

            <select-option :options="billing_options" @monthly="main_billing = 'Monthly'" @yearly="main_billing = 'Yearly'" location="right">
              <btn type="outline" size="small">Billed: {{main_billing}} <span class="fa fa-caret-down"></span></btn>
            </select-option>

            <div class="plan-benefits">
              <div class="includes">Plan includes:</div>
              <div class="benefit">
                <span class="fa fa-check"/>Standard Parts
              </div>
              <div class="benefit">
                <span class="fa fa-check"/>New Features & Parts
              </div>
              <div class="benefit">
                <span class="fa fa-check"/>Limited Support
              </div>
            </div>
            <div class="purchase-button" @click="purchase(main_billing == 'Yearly' ? 'price_1Kaj8aH6pG6cvCgpkvlIL1R0' : 'price_1Kaj8KH6pG6cvCgptUcYlKKT')">
              Purchase
            </div>
          </div>
          <div class="plan custom">
            <div class="plan-title">
              ENTERPRISE
            </div>
            <div class="plan-benefits">
              <div class="includes">Plan includes:</div>
              <div class="benefit">
                <span class="fa fa-check"/>Custom Parts
              </div>
              <div class="benefit">
                <span class="fa fa-check"/>Custom Models
              </div>
              <div class="benefit">
                <span class="fa fa-check"/>Custom Integrations
              </div>
              <div class="benefit">
                <span class="fa fa-check"/>Custom Pricing
              </div>
              <div class="benefit">
                <span class="fa fa-check"/>Unlimited Support
              </div>
            </div>
            <div class="purchase-button" @click="mailTo()">
              Contact Sales
            </div>
          </div>
        </div>
      </div>
    </div>
  </Banner>
</template>

<style lang="scss">
@import "~@/styles/constants";
#pricing-page { 
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(146,48,202);
  background: linear-gradient(156deg, rgba(146,48,202,1) 0%, rgba(201,133,82,1) 100%);    
  min-height: calc(100vh - 4rem);
	.pricing-container { 
    display: flex;
    flex-direction: column;
    align-items: center;
    .pricing-sub-title{
      font-size: 1.5rem;
      padding-top: 4rem;
      padding-bottom: 1rem;
    }
		.pricing-title { 
      font-size: 2rem;
      padding-bottom: 4rem;
		}
		.plan-container { 
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 10rem;
      padding: 0rem 2rem;
			.plan {   
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 24rem;
        height: 32rem;
        box-shadow: $z_depth_3;
        z-index: 1;
        position: relative;
        border-radius: 4px;
        margin-bottom: 2rem;
        color: #666;
        background-color: #ffffff;
        &.special{
          border-radius: 4px 0px 0px 4px;
          .purchase-button {
            background-color: $p;
          }
        }
        &.main{
          z-index: 2;
          height: 36rem;
          .purchase-button {
            background-color: $p;
          }
        }
        &.custom{
          border-radius: 0px 4px 4px 0px;
          .purchase-button {
            background-color: $black_3;
            &:hover, &.hover{
              cursor: pointer;
              background-color: $black_4;
            }
            &:active{
              background-color: $black_2;
            }
          }
        }
				.plan-title { 
          padding-top: 2rem;
          padding-bottom: 2rem;
          font-size: 1.5rem;
          font-weight: 700;
				}

				.plan-price { 
					.value { 
            font-weight: 700;
            font-size: 3rem;
					}
					.interval { 
            font-size: 1.5rem;
					}
				}
				.per-user { 
          font-size: 1.25rem;
          margin-bottom: 2rem;
				}

        .select-container{
          margin-bottom: 2rem;
          .card-options{
            .button{
              .fa{
                margin-right: 0rem;
                margin-left: 0.5rem;
              }
            }
          }
          .options-container{
            background-color: #FFF;
            .option{
              &:hover, &.hover{
                cursor: pointer;
                background-color: #EEE;
              }
              &:active{
                color: #666;
                background-color: #DDD;
              }
            }
          }
        }

				.plan-benefits { 
          flex: 1;
          .includes{
            margin-bottom: 0.75rem;
          }
					.benefit { 
            display: flex;
            align-items: center;
            padding: 0.5rem 0rem;
            font-size: 1.25rem;
						.fa { 
              font-size: 1.25rem;
              margin-right: 1rem;
              color: rgb(30, 201, 121);
						}
					}
				}

				.purchase-button { 
          justify-self: end;
          font-size: 1.25rem;
          color: #FFF;
          background-color: $p; //rgba(255,255,255,0.1);
          display: flex;
          align-items: center;
          justify-content: center;
          width: calc(100% - 4rem);
          padding: 1rem 1.5rem;
          margin: 1.5rem 0.5rem;
          border-radius: 0.25rem;
          &:hover, &.hover{
            cursor: pointer;
            background-color: #bf7ff7;
          }
          &:active{
            background-color: $p1;
          }
				}
			}
		}
	}
}
</style>