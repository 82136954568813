<script>
  import In from "@/components/atoms/In";
  import Btn from "@/components/atoms/Btn";
  import Banner from "@/components/atoms/Banner";
  import SaveFile from "@/components/atoms/SaveFile";
  import Loading from "@/components/atoms/Loading";
  export default {
    components: { In, Btn, Banner, SaveFile, Loading },
    data(){
      return {
        new_project: false,
        new_project_name: '',
      }
    },
    computed: {
      saved(){
        return this.$store.get('saveFiles/saved');
      },
      existingSavedFile(){
        return this.$store.get('saveFiles/saveFilesById', this.$store.get('saveFiles/activeSaveFileId'))
      }
    },
    watch: {
      saved(){
        if(this.saved){
          this.new_project = false;
          this.new_project_name = '';
        }
      }
    }
  }
</script>

<template>
  <Banner>
    <div id="save-page">
      <transition name="fade-loading">
        <div class="overlay" v-if="$store.get('saveFiles/saving') || $store.get('saveFiles/saved') || $store.get('saveFiles/saving_error')" >
          <div class="module saving" v-if="$store.get('saveFiles/saving')">
            <img class="press" :src="'./images/TakeAPicture.png'" />
            <div class="text">
              Take a picture of your project
            </div>
            <div class="sub-text">
              Press the right index trigger
            </div>
          </div>
          <div class="module saved" v-if="$store.get('saveFiles/saved')">
            <i class="fas fa-box-check"></i>
            <div class="saved">
              Saved Successfully
            </div>
          </div>
          <div class="module saving" v-if="$store.get('saveFiles/saving_error')">
            <i class="fas fa-times"></i>
            <div class="text">
              Unable To Save Project
            </div>
            <div class="sub-text">
              {{$store.get('saveFiles/saving_error')}}
            </div>
          </div>
        </div>
      </transition>
      <div class="save-module">
        <div class="save-title">
          Save as
        </div>
        <div class="new-project" :class="{'add-name': new_project}" @click="() => new_project = true">
          <div v-if="!new_project" class="new">
            + New Project
          </div>
          <div v-else class="create-project">
            <div class="create-project-title">
              Project Name
            </div>
            <in v-model="new_project_name" class="input" placeholder="My Awesome Project" :autofocus="true"></in>
            <btn type="fill" @click="$store.dispatch('saveFiles/triggerSaveNewProject', {name: new_project_name})">Save Project</btn>
          </div>
        </div>
        <SaveFile v-if="existingSavedFile" :save-file="existingSavedFile" @click="$store.dispatch('saveFiles/triggerSaveExistingProject', {id: existingSavedFile.id, name: existingSavedFile.name})"/>
      </div>
    </div>
  </Banner>
</template>

<style lang="scss" scopeds>
@import "~@/styles/constants";
#save-page{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .overlay{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    .module{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 5rem 6rem;
      border-radius: 0.5rem;
      &.saving{
        background-color: #444;
      }
      &.saved{
        background-color: #48c586;
      }
      .press{
        width: 15rem;
        margin-bottom: 4rem;
      }
      .text{
        font-size: 2rem;
        margin-bottom: 1.5rem;
      }
      .sub-text{
        font-size: 1.25rem;
      }
      .fas{
        font-size: 3rem;
        margin-bottom: 1.5rem;
        // color: #64f7ad;
      }
      .saved{
        font-size: 2rem;
        // color: #64f7ad;
      }
    }
  }
  .save-module{
    padding-bottom: 4rem;
    .save-title{
      font-size: 2rem;
      margin: 2rem;
    }
    .input{
      margin-bottom: 1rem;
    }
    .new{
      font-size: 1.5rem;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .create-project{
      padding: 2rem 3rem;
      width: 100%;
      .create-project-title{
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }
      .input{
        margin-bottom: 1rem;
      }
    }
    .project-name{
      font-size: 1.5rem;
    }
    .new-project{
      width: 50rem;
      max-height: 16rem;
      min-height: 16rem;
      display: flex;
      align-items: center;
      flex-direction: row;
      margin: 2rem;
      margin-top: 3rem;
      border-radius: 0.5rem;
      transition: transform 0.3s ease;
      box-shadow: $z_depth_2;
      background-color: $card_background;
      &.add-name{
        &:hover, &.hover{
          transform: scale(1);
        }
        * {
          pointer-events: auto;
        }
      }
      &:hover, &.hover{
        transform: scale(1.025);
        cursor: pointer;
      }
      * {
        pointer-events: none;
      }
    }
  }
  .fade-loading-enter-active{
    transition: all 0.4s;
  }
  .fade-loading-leave-active{
    transition: all 0.2s;
  }
  .fade-loading-enter{
    opacity: 0;
  }
  .fade-loading-leave-to{
    opacity: 0;
  }
}
</style>