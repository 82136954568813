<script>
export default {
  props: ['value'],
  computed: {
    active(){
      return this.$store.get('rail_height_filter') == this.value;
    }
  },
  methods: {
    setFilter(value){
      this.$store.set("toggleFilter", {filter: "rail_height_filter", value: this.value})
    }
  }
};
</script>


<template>
  <div class="filter-button" @click="setFilter(value)" :class="{active}">
    {{value}}
  </div>
</template>