<script>
import "@/lib/unity";

import Banner from "@/components/atoms/Banner";
import Search from "@/components/atoms/Search";
import ButtonToggle from "@/components/atoms/ButtonToggle";
import In from "@/components/atoms/In";
import Btn from "@/components/atoms/Btn";
import BtnRow from "@/components/atoms/BtnRow";
import Loading from "@/components/atoms/Loading";
import Check from '@/components/atoms/Check.vue'

import { gsap } from "gsap";
import FormInput from '@/components/atoms/FormInput.vue';
import { event } from 'vue-gtag'

import * as firebaseui from 'firebaseui';
import * as firebase from '@/firebase.js'

const FormState = Object.freeze({
  signIn: 1,
  signUp: 2,
  confirmSignUp: 3,
  resetPassword: 4,
  checkEmail: 5,
})

export default {
  components: { Banner, Search, ButtonToggle, In, Btn, BtnRow, FormInput, Loading, Check },
  data(){
    return {
      FormState,
      formState: FormState.signIn,
      form: {
        email: '',
        password: '',
        confirm_password: '',
        authCode: ''
      },
      loading: false,
      error_message: null,
      forgot_password: false,
      loading_animation: null,
      accept_terms: false,
    }
  },
  computed: {
    to(){
      const urlParams = new URLSearchParams(window.location.href.split('?')[1]);
      return urlParams.get('to')
    }
  },
  methods: {
    login(){
      if(this.formState === FormState.signUp){
        if(this.accept_terms){
          this.signUp()
        }else{
          this.error_message = 'You must agree to the Terms of Use and Privacy Policy.';
        }
      }else{
        this.signIn()    
      }
    },
    signIn(){
      this.clearErrors()
      this.loading = true;
      
      firebase.auth.signInWithEmailAndPassword(this.form.email, this.form.password).then((user) => {
        this.loading = false;
        event('login');
        console.log("Signed in");
        if(this.to){
          this.$router.push({ name: this.to })
        }else{
          this.$router.push({ name: 'Home' })
        }
        
      }).catch((error) => {
        this.loading = false;
        console.log(error);
        this.reject(error.message);
      });
    },
    signUp(){
      this.clearErrors();
      this.validatePassword(()=>{
        this.loading = true;
        firebase.auth.createUserWithEmailAndPassword(this.form.email, this.form.password).then((user) => {
          this.loading = false;
          event('sign_up');
          console.log("Signed up");
          if(this.to){
            this.$router.push({ name: this.to })
          }else{
            this.$router.push({ name: 'Home' })
          }
        }).catch((error) => {
          this.loading = false;
          console.log(error);
          this.reject(error.message);
        });
      })
    },
    forgotPassword(){
      this.formState = FormState.resetPassword;
    },
    sendResetPasswordEmail(){
      this.loading = true;
      firebase.auth.sendPasswordResetEmail(this.form.email, {}).then(()=>{
        this.loading = false;
        console.log("Reset email sent");
        this.formState = FormState.checkEmail;
      }).catch(error=>{
        this.loading = false;
        this.error_message = error;
      }) 
    },
    validatePassword(callback){
      if(this.form.password === this.form.confirm_password){
        callback()
      }else{
        this.reject("Passwords do not match.");
      }
    },
    reject(message){
      console.log(this.$refs);
      gsap.fromTo(this.$refs['sign-in-button'].$el, {x:-3}, {x:3, clearProps:"x", repeat:4, duration: 0.1})
      this.error_message = message;
    },
    clearErrors(){
      this.error_message = null;
      this.forgot_password = false;
    },
    toggleState(){
      this.clearErrors();
      this.formState === FormState.signUp ? this.formState = FormState.signIn : this.formState = FormState.signUp
    }
  },
};
</script>

<template>
  <div id="sign-in-page">
    <Banner :clear="true" :no-avatar="true">
      <template v-slot:banner>
      
      </template>
      <div class="sign-in-container">
        <div class="sign-in-module">
          <Loading :loading="loading" />
          <div v-if="formState === FormState.signUp" class="sign-in-title">
            Create Account
          </div>
          <div v-else-if="formState === FormState.signIn" class="sign-in-title">
            Login
          </div>
          <div v-else-if="formState === FormState.resetPassword"  class="sign-in-title">
            Reset Password
          </div>
          <div v-else-if="formState === FormState.checkEmail"  class="sign-in-title">
            Password Reset Email Sent!
          </div>
          <div v-if="formState === FormState.checkEmail">
            <div class="button-container">
              <btn ref="sign-in-button"  size="large" type="fill" stretch="full" @click="()=>{formState = FormState.signIn}">
                <span>Return To Login</span>
              </btn>
              <div class="divider">
                <span class="line" /><span class="text">or</span><span class="line" />
              </div>
              <btn ref="sign-in-button" size="large" type="fill" stretch="full" color="secondary" @click="sendResetPasswordEmail()">
                <span>Resend Email</span>
              </btn>
            </div>
          </div>
          <div v-else>
            <div class="input-container">
              <in v-model="form.email" class="input" placeholder="Email" autocomplete="email" key="email-1"></in>
            </div>
            <div v-if="formState !== FormState.resetPassword" class="input-container">
              <in v-model="form.password" class="input" placeholder="Password" type="password" key="password-1"></in>
            </div>
            <div v-if="formState === FormState.signUp" class="input-container">
              <in v-model="form.confirm_password" class="input" placeholder="Confirm Password" type="password"></in>
            </div>
            <btn class="forgot-password" v-if="formState == FormState.signIn" stretch="link" size="small" color="secondary" @click="forgotPassword()">Forgot password</btn>
            
            <div v-if="formState == FormState.signUp" class="terms">
              <Check :value="accept_terms" @input="accept_terms = $event"></Check>
              <div>
                <div>I certify that I have read and accept the</div>  
                <div><Btn size="small" color="secondary" @click="$store.set('show_terms_of_use', true)">Terms of Use</Btn> and <Btn size="small" color="secondary" @click="$store.set('show_privacy_policy', true)">Privacy Policy</Btn>.</div>  
              </div> 
            </div>
            <div class="error" v-if="error_message">
              {{error_message}}
            </div>
            <div v-if="formState !== FormState.resetPassword" class="button-container">
              <btn ref="sign-in-button" size="large" type="fill" stretch="full" @click="login()">
                <span v-if="formState === FormState.signUp">Sign Up</span><span v-else>Login</span>
              </btn>
              <div class="divider">
                <span class="line" /><span class="text">or</span><span class="line" />
              </div>
              <btn type="fill" size="large" stretch="full" color="secondary" @click="toggleState()">
                <span v-if="formState === FormState.signUp">Login</span><span v-else>Sign Up</span>
              </btn>
            </div>
            <div v-else class="button-container">
              <btn ref="sign-in-button" size="large" type="fill" stretch="full" @click="sendResetPasswordEmail()">
                <span>Send Email</span>
              </btn>
            </div>
          </div>
        </div>  
      </div>
    </Banner>
  </div>
</template>

<style lang="scss">
  @import "~@/styles/constants";
  #sign-in-page{
    .sign-in-container{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .sign-in-module{
        background-color: $black_2;
        margin-top: 4rem;
        padding: 4rem;
        .sign-in-title{
          font-size: 1.75rem;
          margin-bottom: 2rem;
        }
        .forgot-password{
          margin-bottom: -1rem;
        }
        .input-container{
          width: 24rem;
          margin-top: 1.5rem;
        }
        .button-container{
          margin-top: 2rem;
          .divider{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 1rem 0rem;
            .line{
              border-bottom: 2px solid #AAA;
              flex: 1;
            }
            .text{
              padding: 0rem 1rem;
              color: #AAA;
              font-size: 1.25rem;
            }
          }
        }
      }
    }
  }
  
  .error{
    width: 336px;
    margin-top: 16px;
    color: #e48181;
    padding: 0rem 0.75rem;
    .fa{
      margin-right: 8px;
      margin-left: 4px;
    }
  }
  .terms{
    display: flex;
    align-items: flex-start;
    margin-top: 1.15rem;
    .button{
      text-decoration: underline;
      &.small{
        padding: 0.25rem;
        margin: -0.25rem;
      }
    }
    .checkbox {
      margin-right: 1rem;
      padding: 0.75rem;
    }
  }
  .forgot-password{
    margin-top: 8px;
  }
  
</style>
