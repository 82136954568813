<script>
import Vuescroll from 'vuescroll';

export default {
  components: { Vuescroll },
  data() {
    return {
      ops: {
        vuescroll: {
          mode: 'native',
        },
        rail: {
          size: "20px"
        },
        bar: {
          size: "12px",
          background: "#666"
        }
      }
    }
  },
};
</script>

<template>
  <div class="drawer-area">
    <div class="drawer">
      <Vuescroll :ops="ops">
        <slot name="drawer"></slot>
      </Vuescroll>
    </div>
    <div class="page">
      <slot name="page"></slot>
    </div>
  </div>
</template>

<style lang="scss">
@import "~@/styles/constants";
.drawer-area {
  height: 100%;
  display: flex;
  align-items: stretch;
  .drawer {
    width: 30rem;
    background-color: $black_3;
  }
  .page {
    flex: 1;
    overflow: auto;
  }
}
</style>