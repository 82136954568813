<script>
import lottie from 'lottie-web';
export default {
  props: ["loading"],
  mounted(){
    this.loading_animation = lottie.loadAnimation({
      container: this.$refs['loading-container'+this._uid], // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: false,
      path: '../../animations/loading-red.json' // the path to the animation json
    });
  },
  watch: {
    'loading': function(val){
      if(val){
        this.loading_animation.playSegments([0,30])
      }
    }
  },
}
</script>

<template>
  <transition name="fade-loading">
    <div class="loading" v-show="loading">
      <div class="loading-container animation-container" :ref="'loading-container'+_uid"></div>
    </div>
  </transition>
</template>

<style lang="scss">
  .loading{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    .animation-container{
      width: 128px;
      height: 128px;
    }
  }
  .fade-loading-enter-active{
    transition: all 0.2s;
  }
  .fade-loading-leave-active{
    transition: all 0.2s;
  }
  .fade-loading-enter{
    opacity: 0;
  }
  .fade-loading-leave-to{
    opacity: 0;
  }
</style>