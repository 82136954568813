<script>
  import In from "@/components/atoms/In";
  import Btn from "@/components/atoms/Btn";
  import Banner from "@/components/atoms/Banner";
  import SaveFile from "@/components/atoms/SaveFile";
  import Loading from "@/components/atoms/Loading";
  export default {
    components: { In, Btn, Banner, SaveFile, Loading },
    computed: {
      saveFiles(){
        // console.log(this.$store.get('saveFiles/saveFiles').length)
        return this.$store.get('saveFiles/saveFiles')
      }
    }
  }
</script>

<template>
  <Banner>
    <div id="load-page">
      <Loading :loading="$store.get('saveFiles/loading')"/>
      <div class="save-module">
        <div class="save-title">
          My Projects
        </div>
        <SaveFile v-for="saveFile in saveFiles" :key="saveFile.id" :save-file="saveFile" :show-options="true" @load="$store.dispatch('saveFiles/loadFromCloud', saveFile)" @click="$router.push('/project/'+saveFile.id)"/>
      </div>
    </div>
  </Banner>
</template>

<style lang="scss">
@import "~@/styles/constants";
#load-page{
  display: flex;
  align-items: center;
  justify-content: center;
  .save-module{
    padding-bottom: 6rem;
    .save-title{
      font-size: 2rem;
      margin: 2rem;
    }
  }
}
</style>