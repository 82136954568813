import Vue from 'vue'
import VueRouter from 'vue-router'

import Library from '../views/library/Library.vue'
import Parts from '../views/library/parts/Parts.vue'
import PartDetails from '../views/library/parts/PartDetails.vue'
import PartCategories from '../views/library/parts/PartCategories.vue'
import PartCategory from '../views/library/parts/PartCategory.vue'

import Prefabs from '../views/library/Prefabs.vue'
import Models from '../views/library/Models.vue'

import SignIn from '../views/SignIn.vue'
import Home from '../views/Home.vue'
import Project from '../views/Project.vue'
import Load from '../views/Load.vue'
import Save from '../views/Save.vue'
import Checkout from '../views/Checkout.vue'
import Orders from '../views/Orders.vue'
import Pricing from '../views/Pricing.vue'
import PaymentSuccess from '../views/PaymentSuccess.vue'
import PaymentCanceled from '../views/PaymentCanceled.vue'
import Settings from '../views/Settings.vue'
import Shapes from '../views/Shapes.vue'

import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/library',
    name: 'Library',
    component: Library,
    children: [
      {
        path: '/parts',
        name: 'Parts',
        component: Parts,
        children: [
          {
            path: '/parts/categories/:part_category/.../:part_id_1/:part_id',
            name: 'MultiRelatedPartDetails',
            component: PartDetails,
            meta: { requiresAuth: true } 
          },
          {
            path: '/parts/categories/:part_category/:part_id_1/:part_id',
            name: 'RelatedPartDetails',
            component: PartDetails,
            meta: { requiresAuth: true } 
          },
          {
            path: '/parts/categories/:part_category/:part_id',
            name: 'PartDetails',
            component: PartDetails,
            meta: { requiresAuth: true } 
          },
          {
            path: '/parts/categories/:part_category',
            name: 'PartCategory',
            component: PartCategory,
            meta: { requiresAuth: true } 
          },
          {
            path: '/parts',
            alias: '/parts/categories',
            name: 'PartCategories',
            component: PartCategories,
            meta: { requiresAuth: true } 
          },
          {
            path: '/parts/:part_id',
            name: 'PartExact',
            component: PartDetails,
            meta: { requiresAuth: true } 
          },
        ]
      },
       {
        path: 'prefabs',
        name: 'Prefabs',
        component: Prefabs,
        meta: { requiresAuth: true } 
      }, {
        path: 'models',
        name: 'Models',
        component: Models,
        meta: { requiresAuth: true } 
      },
    ]
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
    meta: { requiresAuth: true } 
  },{
    path: '/orders',
    name: 'Orders',
    component: Orders,
    meta: { requiresAuth: true } 
  },{
    path: '/signin',
    name: 'SignIn',
    component: SignIn
  },{
    path: '/home',
    alias: '/home',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true, requiresMembership: true } 
  },{
    path: '/project/:project_id',
    name: 'Project',
    component: Project,
    meta: { requiresAuth: true, requiresMembership: true } 
  },{
    path: '/',
    alias: ['/load', '/projects'],
    name: 'Load',
    component: Load,
    meta: { requiresAuth: true, requiresMembership: true } 
  },{
    path: '/save',
    name: 'Save',
    component: Save,
    meta: { requiresAuth: true, requiresMembership: true } 
  },{
    path: '/pricing',
    name: 'Pricing',
    component: Pricing,
  },{
    path: '/payment-success',
    name: 'PaymentSuccess',
    component: PaymentSuccess,
  },{
    path: '/payment-canceled',
    name: 'PaymentCanceled',
    component: PaymentCanceled,
  },{
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: { requiresAuth: true } 
  },{
    path: '/shapes',
    name: 'Shapes',
    component: Shapes,
    meta: { requiresAuth: true } 
  }
]

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
})

router.beforeResolve((to, from, next) => {
  store.dispatch('auth/checkUserSignIn').then(response=>{  

    var creationTime = new Date(response.metadata.creationTime);
    var now = new Date();

    var differenceInTime = now.getTime() - creationTime.getTime();
    var differenceInDays = differenceInTime / (1000 * 3600 * 24);

    store.dispatch('auth/checkUserSubscription').then(response=>{  
      next()
    }).catch(response => {
      if(differenceInDays <= 30 && differenceInDays > 23 && store.get('show_trail_ending_msg')){
        store.commit('show_trail_ending_msg', false);
        store.commit('confirm', {
          title: "You have " + (30 - Math.ceil(differenceInDays)) + " days left in your free trial.",
          description: "Subscribe now to get unlimited access.",
          start_label: "Subscribe",
          stop_label: "Close",
          start: ()=>{
            router.push({name: 'Pricing'})
          },
        }, {root: true});
      }
  
      if(to.matched.some(record => record.meta.requiresMembership)){
        if(differenceInDays > 30){
          next({
            path: '/pricing'
          });
          store.commit('confirm', {
            title: "Your free trial has expired.",
            description: "We hope you have enjoyed your experience so far. Please subscribe to get unlimited access.",
            start_label: "Close",
            stop_label: "NONE",
          }, {root: true});
        }else{
          next()
        }   
      }else{
        next()
      }
    })
  }).catch(e=>{
    if(to.matched.some(record => record.meta.requiresAuth)){
      next({
        path: '/signin?to='+to.name
      });
    }else{
      next()
    }
  })
})

export default router
