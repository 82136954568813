<script>
import PartCard from "@/components/atoms/PartCard";

const MAX_CARDS = 3;

export default {
  components: { PartCard },
  computed: {
    category(){
      this.$gtag.event('view_category', {'event_category': 'flow', 'event_label': this.$route.params.part_category});
      return this.$store.get("parts/categoryById", this.$route.params.part_category)
    }
  }
};
</script>

<template>
  <div id="categories">
    <div class="card-section">
      <div class="category-title-area" @click="$router.push('/parts/categories')">
        <div class="back">
          <i class="fas fa-chevron-left fa-sm"></i> Back
        </div>
        <div class="category-title">
          {{ category.name }} <span class="count">({{$store.get('parts/getPartsCount', category)}})</span>
        </div>
      </div>
      <div class="card-area">
        <PartCard
          v-for="(part, index) in $store.get('parts/partsByCategory', category)"
          :key="index"
          :part="part"
          :link="'/parts/categories/'+category.id+'/'+part.id"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "~@/styles/constants";
.card-section {
  .category-title-area {
    display: inline-block;
    margin: 1rem 2rem;
    padding: 0.5rem 1rem;
    position: relative;
    border-radius: 0.5rem;
    &:hover, &.hover {
      background-color: $black_2;
      .back{
        top: 1.75rem;
        font-size: 2rem;
      }
      .category-title{
        opacity: 0;
      }
    }
    * {
      pointer-events: none;
    }
    .back{
      position: absolute;
      top: 1rem;
      left: 1.5rem;
      font-size: 1.25rem;
      padding-bottom: 0.25rem;
      display: flex;
      align-items: center;
      transition: all 0.3s ease;
      i{
        margin-right: 0.5rem;
      }
    }
    .category-title {
      padding-top: 2rem;
      font-size: 2.5rem;
      opacity: 1;
      transition: all 0.3s ease;
      .count{
        margin-left: 0.5rem;
        color: #888;
      }
      i{
        margin-right: 0.5rem;
      }
    }
    .view-all {
      font-size: 1rem;
    }
  }
}
.card-area{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0rem 2rem;
  margin-bottom: 2rem;
}
</style>