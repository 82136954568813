<script>
import Btn from "@/components/atoms/Btn";
import SelectOption from '@/components/atoms/SelectOption.vue'
export default{
  components: { Btn, SelectOption, },
  props: ["clear", "noAvatar", "showFooter", "to"],
  data(){
    return {
      origin: window.location.origin,
      windowWidth: null,
    }
  },
  mounted() {
    this.$nextTick(() => {
       this.onResize();
      window.addEventListener('resize', this.onResize);
    })
  },
  computed: {
    account_options(){
      return ['', 'Settings', '', 'Sign Out']
    },
    route(){
      return this.to ? this.to : this.$route.name
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    open(name, link){
      if(this.$route.name.toLowerCase() != name){
        window.open(link,"_self")
      }
    },
    openHome(){
      if(this.fromMarketing){
        window.open('https://www.quadrobee.com/',"_self")
      }else{
        this.$router.push({name: 'Home'})
      }
    }
  }
}
</script>

<template>
  <div class="banner-layout" :class="{clear}">
    <div class="banner" v-if="!$store.get('vr')">
      <div class="logo" @click="openHome()">
        <img src="@/assets/Quadrobee.svg" />
      </div>
      <template v-if="windowWidth > 1000">
        <slot name="banner"></slot>
        <div class="center" v-if="!noAvatar">
          <div class="links">
            <div class="link" :class="{'active': $route.name.toLowerCase() == 'load' || $route.name.toLowerCase() == 'project'}" @click="$router.push('/projects')">
              My Projects
            </div>
            <div class="link" :class="{'active': $route.path.includes('parts')}" @click="$router.push('/parts/categories')">
              Parts
            </div>
          </div>
          <!-- <div v-else class="links marketing">
            <div class="link" :class="{'active': $route.name.toLowerCase() == 'howitworks'}" @click="open('howitworks', 'https://www.quadrobee.com/how-it-works')">
              How It Works
            </div>
            <div class="link" :class="{'active': $route.name.toLowerCase() == 'getstarted'}" @click="open('getstarted', 'https://www.quadrobee.com/get-started')">
              Get Started
            </div>
            <div class="link" :class="{'active': $route.name.toLowerCase() == 'pricing'}" @click="$router.push('/pricing')">
              Pricing
            </div>
            <div class="link" :class="{'active': $route.name.toLowerCase() == 'about'}" @click="open('about', 'https://www.quadrobee.com/about')">
              About
            </div>
            <div class="link" :class="{'active': $route.name.toLowerCase() == 'contact'}" @click="open('contact', 'https://www.quadrobee.com/contact')">
              Contact
            </div>
          </div> -->
        </div>
        <div v-if="!noAvatar" class="user-avatar">
          <div v-if="this.$store.get('auth/signed_in')">
            <select-option :options="account_options" @settings="$router.push('/settings')" @sign-out="$store.dispatch('auth/signOut')" @pricing="$router.push('/pricing')" @my-subscriptions="$store.dispatch('payment/openStripePortal')">
              <template v-slot:info>
                <div class="signed-in-user">
                  <div class="label">
                    Signed in as:
                  </div>
                  <div class="username">
                    {{$store.get('auth/user').email}}
                  </div>
                </div>
              </template>
              <span class="user fa fa-user-circle"></span>
            </select-option>
          </div>
          <div v-else>
            <btn type="fill" @click="$router.push('/signin?to='+route)">Login</btn>
          </div>
        </div>
      </template>
    </div>
    <div class="main-area" :class="{'vr': $store.get('vr')}">
      <slot></slot>
    </div>
    <div class="footer" v-if="showFooter && !$store.get('vr')">
      <img src="@/assets/AlloyXRWhite.png" />
      <div class="links">
        <div class="link" @click="$store.set('show_terms_of_use', true)">Term of Use</div>
        <div class="link" @click="$store.set('show_privacy_policy', true)">Privacy Policy</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "~@/styles/constants";
.banner-layout {
  &.clear{
    .banner{
      background-color: rgba(0,0,0,0);
    }
  }
  .banner {
    z-index: 100;
    width: 100%;
    height: 4rem;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding: 0rem 1.5rem;
    background-color: $black_0;
    .logo {
      display: flex;
      align-items: center;
      margin-right: 2rem;
      img {
        width: 10rem;
      }
    }
    .center{
      flex: 1;
      .links{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &.marketing{
          justify-content: center;
        }
        .link{
          font-size: 1.25rem;
          padding: 1rem;
          font-weight: 700;
          color: #CCC;
          &.active{
            color: $p;
          }
          &:hover, &.hover{
            color: #FFF;
            &.active{
              color: $p;
            }
            cursor: pointer;
          }
          * {
            pointer-events: none;
          }
        }
      }
    }
    .button{
      font-size: 1.15rem;
      font-weight: 700;
      padding: 0.5rem 2rem;
      background-color: #8F3AFD;
    }
    .signed-in-user{
      padding: 1rem 2rem;
      .label{
        font-size: 1rem;
        color: $w_1;
        margin-bottom: 0.5rem;
      }
      .username{
        
      }
    }
    .user{
      font-size: 2.75rem;
    }
  }
  .main-area {
    min-height: 100vh;
    padding-top: 4rem;
    &.vr{
      padding-top: 0rem;
    }
  }
  .footer{
    padding: 0rem 1.5rem;
    display: flex;
    align-items: center;
    img{
      width: 6rem;
      padding-right: 1rem;
    }
    .links{
      display: flex;
      align-items: center;
      .link{
        padding: 0.75rem 1rem;
        color: #FFF;
        text-decoration: none;
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
}
</style>