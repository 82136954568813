import * as Units from '@/lib/units';

export function getPartIdFromLedgerKey(ledgerKey){
  return ledgerKey.split('::')[0];
}
export function getDimensionFromLedgerKey(ledgerKey){
  const split = ledgerKey.split('::');
  return split.length > 1 ? split[1] : null;
}
export function getMachiningFromLedgerKey(ledgerKey){
  const split = ledgerKey.split('::');
  return split.length > 2 ? split[2] : null;
}
export function getTaps(ledgerKey){
  const machining = getMachiningFromLedgerKey(ledgerKey);
  return machining ? machining.split(',') : null;
}
export function getCustomWidth(ledgerKey){
  const partSize = getDimensionFromLedgerKey(ledgerKey);
  return partSize && partSize.split(',').length > 0 ? partSize.split(',')[0] : null;
}
export function getCustomHeight(ledgerKey){
  const partSize = getDimensionFromLedgerKey(ledgerKey);
  return partSize && partSize.split(',').length > 1 ? partSize.split(',')[1] : null;
} 
export function getCustomLength(ledgerKey){
  const partSize = getDimensionFromLedgerKey(ledgerKey);
  return partSize && partSize.split(',').length > 2 ? partSize.split(',')[2] : null;
}
export function getWidth(part, ledgerKey, metric){
  if(part){
    return part.details.width ? null : getCustomWidth(ledgerKey) ? Units.metersToInches(getCustomWidth(ledgerKey), metric) : null;
  }else{
    return null;
  }
}
export function getHeight(part, ledgerKey, metric){
  if(part){
    return part.details.height ? null : getCustomHeight(ledgerKey) ? Units.metersToInches(getCustomHeight(ledgerKey), metric) : null;
  }else{
    return null;
  }
}
export function getLength(part, ledgerKey, metric){
  if(part){
    return part.details.length ? null : getCustomLength(ledgerKey) ? Units.metersToInches(getCustomLength(ledgerKey), metric) : null;
  }else{
    return null;
  }
}