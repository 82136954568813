<script>
  import Btn from "@/components/atoms/Btn";
  import Banner from "@/components/atoms/Banner";
  export default {
    components: { Btn, Banner },
    mounted(){
      this.$gtag.event('purchase')
    }
  }
</script>

<template>
  <Banner>
    <div id="success-page">
      <div class="title">
        Payment Successful!
      </div>
      <div class="sub-title">
        Your account was registered.
      </div>
      <btn type="outline" color="secondary" @click="$router.push({name: 'Home'})">Home</btn>
    </div>
  </Banner>
</template>

<style lang="scss">
@import "~@/styles/constants";
#success-page { 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(146,48,202);
  background: linear-gradient(156deg, rgba(146,48,202,1) 0%, rgba(201,133,82,1) 100%);    
  min-height: calc(100vh - 4rem);
  .title{
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  .sub-title{
    font-size: 1.25rem; 
    margin-bottom: 2rem;
  }

}
</style>
