<script>
import Banner from "@/components/atoms/Banner";
export default {
  components: { Banner },
}
</script>

<template>
  <Banner>
    <div id="shapes-page">
      <div class="shape-row">
        <div class="shape-card" data-vr="Cube">
          <div class="image-container">
            <img class="cube" :src="'./images/parts/Cube.png'" />
          </div>
          <div class="label">
            Cube
          </div>
        </div>
        <div class="shape-card" data-vr="Cylinder">
          <div class="image-container">
            <img class="cylinder" :src="'./images/parts/Cylinder.png'" />
          </div>
          <div class="label">
            Cylinder
          </div>
        </div>
        <div class="shape-card" data-vr="Sphere">
          <div class="image-container">
            <img class="sphere" :src="'./images/parts/Sphere.png'" />
          </div>
          <div class="label">
            Sphere
          </div>
        </div>
        <div class="shape-card" data-vr="pyramid">
          <div class="image-container">
            <img class="pyramid" :src="'./images/parts/Pyramid.png'" />
          </div>
          <div class="label">
            Pyramid
          </div>
        </div>
        <div class="shape-card" data-vr="cone">
          <div class="image-container">
            <img class="cone" :src="'./images/parts/Cone.png'" />
          </div>
          <div class="label">
            Cone
          </div>
        </div>
        <div class="shape-card" data-vr="diamond">
          <div class="image-container">
            <img class="diamond" :src="'./images/parts/Diamond.png'" />
          </div>
          <div class="label">
            Diamond
          </div>
        </div>
      </div>
    </div>
  </Banner>
</template>

<style lang="scss">
@import "~@/styles/constants";
#shapes-page{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  .shape-row{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 100rem;
    margin: 2rem 0rem;
    .shape-card {
      width: 24rem;
      height: 26rem;
      min-width: 24rem;
      min-height: 26rem;
      margin: 1rem;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      background-color: $card_background;
      box-shadow: $z_depth_2;
      transition: transform 0.3s ease;
      border-radius: 0.5rem;
      position: relative;
      &:hover, &.hover{
        transform: scale(1.025);
        cursor: pointer;
        .grab-indicator{
          // opacity: 1;
        }
      }
      * {
        pointer-events: none;
      }
      .image-container{
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        .cube{
          padding-left: 1rem;
        }
        img{
          width: 16rem;
        }
      }
      .label{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        height: 6rem;
        font-weight: 600;
        font-size: 2rem;
      }
    }
  }
}
</style>