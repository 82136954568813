<script>
  export default {
    props: [ "value" ],
    computed: {
      active_value: {
        get(){
          return this.value;
        },
        set(val){
          this.$emit("update:value", val);
          this.$emit("input", val);
        }
      },
    }
  }
</script>

<template>
  <div class="search">
    <span class="search-icon far fa-search"></span>
    <input v-model="active_value" placeholder="Search"/>
    <span v-if="active_value && active_value.length > 0" @click="active_value = ''" class="clear-icon far fa-times"></span>
  </div>
</template>

<style lang="scss" scoped>
  @import "~@/styles/constants";
  .search{
    display: flex;
    align-items: center;
    position: relative;
    // width: 100%;
    flex: 1;
    .search-icon{
      position: absolute;
      top: 50%;
      left: 14px;
      transform: translateY(-50%);
      color: #CCC;
    }
    .clear-icon{
      position: absolute;
      top: 50%;
      right: 12px;
      padding: 6px;
      transform: translateY(-50%);
      color: $input-placeholder;
      &:hover, &.hover{
        cursor: pointer;
        color: $input-border;
      }
      * {
        pointer-events: none;
      }
    }
    input{
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-height: 16px;
      padding: 6px 34px;
      flex-basis: 300px;
      color: $input;
      border-radius: 1rem;
      border: 2px solid $input-border;
      box-shadow: $z_depth_0;
      font-weight: 600;
      font-size: 20px;
      background-color: $black_2;
      &::placeholder{
        color: $input-placeholder;
      }
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $input-placeholder;
      }
      &:focus {
        border-color: $p;
        outline: 0;
      }
    }
  }
</style> 