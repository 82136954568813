<script>
export default {
  props: ['value', 'width'],
  computed: {
    active(){
      return this.$store.get('construction_filter') == this.value;
    }
  },
  methods: {
    setFilter(value){
      this.$store.set("toggleFilter", {filter: "construction_filter", value: this.value})
    }
  }
};
</script>


<template>
  <div class="filter-icon" @click="setFilter(value)" :class="{active}">
    <img :src="'./icons/'+value+'.svg'" :style="{width: '2rem'}"/>
    <div class="icon-label">{{value}}</div>
  </div>
</template>