import { PRICE_OPTIONS, PART_CONSTRUCTION, RAIL_PROFILE } from '@/data/constants';

export default [

  //////////////////////////
  /////// 45 Series ////////
  //////////////////////////

  {
    id: '45-4545',
    name: '45x45mm',
    sub_name: 'Aluminum Extrusion',
    metric: true,
    categories: [
      "extrusions"
    ],
    image: {
      name: "45-4545.png",
      style: {
        padding: '0rem 4rem 2rem 0rem'
      }
    },
    details: {
      width: '45mm',
      height: '45mm',
      length: null,
      series: ['45'],
      profile: ['Single'],
      slot_width: '10mm',
      construction: 'Hollow',
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/45-4545.html"],
      //   price: '$0.32 cm',
      //   surcharge: "$2.79"
      // },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/5537T907"],
        price: '$0.62 cm',
      },
      // {
      //   name: "TNutz",
      //   links: ["https://www.tnutz.com/product/exm-4545l/"
      // },
      // {
      //   name: "TSlots",
      //   links: ["https://www.tslots.com/product/item-670030/"
      // },
    },
    related_parts: [
      
    ],
  }, 
  {
    id: '45-9090',
    name: '90x90mm',
    sub_name: 'Aluminum Extrusion',
    metric: true,
    categories: [
      "extrusions"
    ],
    image: {
      name: "45-9090.png",
      style: {
        padding: '0rem 4rem 1rem 0rem'
      }
    },
    details: {
      width: '90mm',
      height: '90mm',
      length: null,
      series: ['45'],
      profile: ['Quad'],
      slot_width: '10mm',
      construction: 'Hollow',
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/45-9090.html"],
      //   price: "$1.28 cm",
      //   surcharge: "$3.80"
      // },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/5537T918/"],
        price: "$1.32 cm"
      },
    },
    related_parts: [
      
    ],
  }, 
  {
    id: '45-4590',
    name: '45x90mm',
    sub_name: 'Aluminum Extrusion',
    metric: true,
    categories: [
      "extrusions"
    ],
    image: {
      name: "45-4590.png",
      style: {
        padding: '0rem 4rem 1rem 0rem'
      }
    },
    details: {
      width: '45mm',
      height: '90mm',
      length: null,
      series: ['45'],
      profile: ['Double'],
      slot_width: '10mm',
      construction: 'Hollow',
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/45-4590.html"],
      //   price: "0.75 cm",
      //   surcharge: "$3.51"
      // },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/5537T916/"],
        price: "0.80 cm",
      },
    },
    related_parts: [
      
    ],
  }, 
  {
    id: '45-4338',
    name: '8 Hole',
    sub_name: 'Gusseted Inside Corner Bracket',
    metric: true,
    categories: [
      "brackets"
    ],
    image: {
      name: "45-4338.png",
      style: {
        padding: '2rem 2rem 2rem 2rem'
      }
    },
    details: {
      width: '87mm',
      height: '90mm',
      length: '90mm',
      series: ['45'],
      profile: ['Double', 'Quad'],
      slot_width: null,
      construction: null,
      bolt_width: 'M8',
      bolt_length: '18mm',
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/45-4338.html"],
      //   price: "$13.43",
      // },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/5537T662/"],
        price: "$19.05",
        fastenersIncluded: true,
      },
    },
    related_parts: [
      
    ],
  }, 
  {
    id: '45-4336',
    name: '4 Hole - Tall',
    sub_name: 'Gusseted Inside Corner Bracket',
    metric: true,
    categories: [
      "brackets"
    ],
    image: {
      name: "45-4336.png",
      style: {
        padding: '2rem 2rem 2rem 2rem'
      }
    },
    details: {
      width: '87mm',
      height: '90mm',
      length: '90mm',
      series: ['45'],
      profile: ['Single', 'Double'],
      slot_width: null,
      construction: null,
      bolt_width: 'M8',
      bolt_length: '18mm',
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/45-4336.html"],
      //   price: "$9.42",
      // },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/5537T661/"],
        price: "$14.94",
        fastenersIncluded: true,
      },
    },
    related_parts: [
      
    ],
  }, 
  {
    id: '45-4334',
    name: '4 Hole - Wide',
    sub_name: 'Gusseted Inside Corner Bracket',
    metric: true,
    categories: [
      "brackets"
    ],
    image: {
      name: "45-4334.png",
      style: {
        padding: '4rem 4rem 4rem 4rem'
      }
    },
    details: {
      width: '87mm',
      height: '45mm',
      length: '45mm',
      series: ['45'],
      profile: ['Double', 'Quad'],
      slot_width: null,
      construction: null,
      bolt_width: 'M8',
      bolt_length: '18mm',
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/45-4334.html"],
      //   price: "$9.42",
      // },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/5537T671/"],
        price: "$20.08",
        fastenersIncluded: true,
      },
    },
    related_parts: [
      
    ],
  }, 
  {
    id: '45-4332',
    name: '2 Hole',
    sub_name: 'Gusseted Inside Corner Bracket',
    metric: true,
    categories: [
      "brackets"
    ],
    image: {
      name: "45-4332.png",
      style: {
        padding: '4rem 4rem 4rem 4rem'
      }
    },
    details: {
      width: '42mm',
      height: '45mm',
      length: '45mm',
      series: ['45'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: 'M8',
      bolt_length: '18mm',
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/45-4332.html"],
      //   price: "$6.49",
      // },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/5537T594"],
        price: "$10.05",
        fastenersIncluded: true,
      },
    },
    related_parts: [
      
    ],
  }, 
  {
    id: '45-4304',
    name: '8 Hole',
    sub_name: 'Inside Corner Bracket',
    metric: true,
    categories: [
      "brackets"
    ],
    image: {
      name: "45-4304.png",
      style: {
        padding: '2rem 2rem 2rem 2rem'
      }
    },
    details: {
      width: '87mm',
      height: '90mm',
      length: '90mm',
      series: ['45'],
      profile: ['Double', 'Quad'],
      slot_width: null,
      construction: null,
      bolt_width: 'M8',
      bolt_length: '18mm',
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/45-4304.html"],
      //   price: "$10.45",
      // },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/5537T446/"],
        price: "$19.70",
        fastenersIncluded: true,
      },
    },
    related_parts: [
      
    ],
  }, 
  {
    id: '45-4301',
    name: '4 Hole - Tall',
    sub_name: 'Inside Corner Bracket',
    metric: true,
    categories: [
      "brackets"
    ],
    image: {
      name: "45-4301.png",
      style: {
        padding: '2rem 2rem 2rem 2rem'
      }
    },
    details: {
      width: '87mm',
      height: '90mm',
      length: '90mm',
      series: ['45'],
      profile: ['Single', 'Double'],
      slot_width: null,
      construction: null,
      bolt_width: 'M8',
      bolt_length: '18mm',
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/45-4301.html"],
      //   price: "$6.49",
      // },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/5537T349/"],
        price: "$10.90",
        fastenersIncluded: true,
      },
    },
    related_parts: [
      
    ],
  }, 
  {
    id: '45-4303',
    name: '4 Hole - Wide',
    sub_name: 'Inside Corner Bracket',
    metric: true,
    categories: [
      "brackets"
    ],
    image: {
      name: "45-4303.png",
      style: {
        padding: '4rem 4rem 4rem 4rem'
      }
    },
    details: {
      width: '87mm',
      height: '45mm',
      length: '45mm',
      series: ['45'],
      profile: ['Double', 'Quad'],
      slot_width: null,
      construction: null,
      bolt_width: 'M8',
      bolt_length: '18mm',
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/45-4303.html"],
      //   price: "$6.66",
      // },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/5537T943/"],
        price: "$11.62",
        fastenersIncluded: true,
      },
    },
    related_parts: [
      
    ],
  }, 
  {
    id: '45-4302',
    name: '2 Hole',
    sub_name: 'Inside Corner Bracket',
    metric: true,
    categories: [
      "brackets"
    ],
    image: {
      name: "45-4302.png",
      style: {
        padding: '4rem 4rem 4rem 4rem'
      }
    },
    details: {
      width: '42mm',
      height: '45mm',
      length: '45mm',
      series: ['45'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: 'M8',
      bolt_length: '18mm',
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/45-4302.html"],
      //   price: "$4.49",
      // },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/5537T938/"],
        price: "$8.01",
        fastenersIncluded: true,
      },
    },
    related_parts: [
      
    ],
  }, 
  {
    id: '45-4367',
    name: '4 Hole',
    sub_name: 'Square Flat Plate',
    metric: true,
    categories: [
      "brackets"
    ],
    image: {
      name: "45-4367.png",
      style: {
        padding: '4rem 4rem 4rem 4rem'
      }
    },
    details: {
      width: '90mm',
      height: '90mm',
      length: '6mm',
      series: ['45'],
      profile: ['Double', 'Quad'],
      slot_width: null,
      construction: null,
      bolt_width: 'M8',
      bolt_length: '18mm',
    },
    vendors: {
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/45-4367.html"],
        price: "$7.46",
      },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/5537T956/"],
        price: "$12.53",
        fastenersIncluded: true,
      },
    },
    related_parts: [
      
    ],
  }, 
  {
    id: '45-4480',
    name: '5 Hole',
    sub_name: 'T Flat Plate',
    metric: true,
    categories: [
      "brackets"
    ],
    image: {
      name: "45-4480.png",
      style: {
        padding: '3rem 3rem 3rem 3rem'
      }
    },
    details: {
      width: '135mm',
      height: '135mm',
      length: '6mm',
      series: ['45'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: 'M8',
      bolt_length: '18mm',
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/45-4480.html"],
      //   price: "$12.02",
      // },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/3136N921/"],
        price: "$25.77",
        fastenersIncluded: true,
      },
    },
    related_parts: [
      
    ],
  }, 
  {
    id: '45-4340',
    name: '5 Hole',
    sub_name: 'T Flat Plate',
    metric: true,
    categories: [
      "brackets"
    ],
    image: {
      name: "45-4340.png",
      style: {
        padding: '3rem 3rem 3rem 3rem'
      }
    },
    details: {
      width: '135mm',
      height: '135mm',
      length: '6mm',
      series: ['45'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: 'M8',
      bolt_length: '18mm',
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/45-4340.html"],
      //   price: "$12.02",
      // },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/5537T974/"],
        price: "$19.92",
        fastenersIncluded: true,
      },
    },
    related_parts: [
      
    ],
  }, 
  {
    id: '45-4481',
    name: '5 Hole',
    sub_name: 'L Flat Plate',
    metric: true,
    categories: [
      "brackets"
    ],
    image: {
      name: "45-4481.png",
      style: {
        padding: '3rem 3rem 3rem 3rem'
      }
    },
    details: {
      width: '135mm',
      height: '135mm',
      length: '6mm',
      series: ['45'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: 'M8',
      bolt_length: '18mm',
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/45-4481.html"],
      //   price: "$10.98",
      // },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/3136N925/"],
        price: "$24.46",
        fastenersIncluded: true,
      },
    },
    related_parts: [
      
    ],
  }, 
  {
    id: '45-4351',
    name: '5 Hole',
    sub_name: 'L Flat Plate',
    metric: true,
    categories: [
      "brackets"
    ],
    image: {
      name: "45-4351.png",
      style: {
        padding: '3rem 3rem 3rem 3rem'
      }
    },
    details: {
      width: '135mm',
      height: '135mm',
      length: '6mm',
      series: ['45'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: 'M8',
      bolt_length: '18mm',
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/45-4351.html"],
      //   price: "$10.98",
      // },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/5537T965/"],
        price: "$18.20",
        fastenersIncluded: true,
      },
    },
    related_parts: [
      
    ],
  }, 
  {
    id: '45-4365',
    name: '8 Hole',
    sub_name: 'Rectangular Flat Plate',
    metric: true,
    categories: [
      "brackets"
    ],
    image: {
      name: "45-4365.png",
      style: {
        padding: '2rem 1rem 1rem 1rem'
      }
    },
    details: {
      width: '90mm',
      height: '180mm',
      length: '6mm',
      series: ['45'],
      profile: ['Double', 'Quad'],
      slot_width: null,
      construction: null,
      bolt_width: 'M8',
      bolt_length: '18mm',
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/45-4481.html"],
      //   price: "$10.98",
      // },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/3136N925/"],
        price: "$24.46",
        fastenersIncluded: true,
      },
    },
    related_parts: [
      
    ],
  }, 
  {
    id: '45-4305',
    name: '4 Hole',
    sub_name: 'Straight Flat Plate',
    metric: true,
    categories: [
      "brackets"
    ],
    image: {
      name: "45-4305.png",
      style: {
        padding: '2rem 1rem 1rem 1rem'
      }
    },
    details: {
      width: '45mm',
      height: '180mm',
      length: '6mm',
      series: ['45'],
      profile: ['Single', 'Double'],
      slot_width: null,
      construction: null,
      bolt_width: 'M8',
      bolt_length: '18mm',
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/45-4305.html"],
      //   price: "$7.46",
      // },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/5537T952/"],
        price: "$13.49",
        fastenersIncluded: true,
      },
    },
    related_parts: [
      
    ],
  }, 
  {
    id: '45-4307',
    name: '2 Hole',
    sub_name: 'Straight Flat Plate',
    metric: true,
    categories: [
      "brackets"
    ],
    image: {
      name: "45-4307.png",
      style: {
        padding: '4rem 4rem 4rem 4rem'
      }
    },
    details: {
      width: '42mm',
      height: '90mm',
      length: '6mm',
      series: ['45'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: 'M8',
      bolt_length: '18mm',
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/45-4307.html"],
      //   price: "$5.32",
      // },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/5537T947/"],
        price: "$9.76",
        fastenersIncluded: true,
      },
    },
    related_parts: [
      
    ],
  }, 
  {
    id: '45-4335',
    name: '7 Hole',
    sub_name: 'Cross Flat Plate',
    metric: true,
    categories: [
      "brackets"
    ],
    image: {
      name: "45-4335.png",
      style: {
        padding: '3rem 3rem 3rem 3rem'
      }
    },
    details: {
      width: '135mm',
      height: '225mm',
      length: '6mm',
      series: ['45'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: 'M8',
      bolt_length: '18mm',
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/45-4335.html"],
      //   price: "$17.08",
      // },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/3136N916/"],
        price: "$36.41",
        fastenersIncluded: true,
      },
    },
    related_parts: [
      
    ],
  }, 
  {
    id: '33490',
    name: 'Inside-Inside',
    sub_name: 'Hidden Corner Connector',
    metric: true,
    categories: [
      "brackets"
    ],
    image: {
      name: "33490.png",
      style: {
        padding: '4rem 4rem 4rem 4rem'
      }
    },
    details: {
      width: '19mm',
      height: '38mm',
      length: '32mm',
      series: ['45'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: 'M8',
      bolt_length: null,
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/33490.html"],
      //   price: "$7.56",
      // },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/5537T323/"],
        price: "$5.05",
        fastenersIncluded: true,
      },
    },
    related_parts: [
      
    ],
  }, 
  {
    id: '14136',
    name: 'Plate Connector',
    metric: true,
    categories: [
      "brackets"
    ],
    image: {
      name: "14136.png",
      style: {
        padding: '4rem 4rem 4rem 4rem'
      }
    },
    details: {
      width: '45mm',
      height: '13mm',
      length: '45mm',
      series: ['45'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: 'M6',
      bolt_length: '16mm',
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/14136.html"],
      //   price: "$6.50"
      // },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/5537T579/"],
        price: "$11.76",
        fastenersIncluded: true,
      },
    },
    related_parts: [
      
    ],
  },
  // {
  //   id: '65-2338',
  //   name: 'Triangular Top Plate Caster',
  //   sub_name: '117.48mm Swivel Radius',
  //   metric: true,
  //   categories: [
  //     "mountingFeet"
  //   ],
  //   image: {
  //     name: "65-2338.png",
  //     style: {
  //       padding: '2rem'
  //     }
  //   },
  //   details: {
  //     width: '139.70mm',
  //     height: '158.75mm',
  //     length: '139.70mm',
  //     series: ['30', '40', '45'],
  //     profile: ['Single'],
  //     slot_width: null,
  //     construction: null,
  //     bolt_width: 'M8',
  //     bolt_length: '16mm',
  //   },
  //   vendors: {
  //     "80/20": {
  //       name: "80/20",
  //       links: ["https://8020.net/65-2338.html"],
  //       price: "$75.47",
  //     },
  //   },
  //   related_parts: [
      
  //   ],
  // },
  {
    id: '14175',
    name: 'Squared Corner Connector',
    sub_name: '3 Way',
    metric: true,
    categories: [
      "brackets"
    ],
    image: {
      name: "14175.png",
      style: {
        padding: '4rem 4rem 4rem 4rem'
      }
    },
    details: {
      width: '45mm',
      height: '45mm',
      length: '45mm',
      series: ['45'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: 'M12',
      bolt_length: '30mm',
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/14175.html"],
      //   price: "$18.44",
      // },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/5537T292/"],
        price: "$14.44",
        fastenersIncluded: true,
      },
    },
    related_parts: [
      
    ],
  },

  //////////////////////////
  /////// 40 Series ////////
  //////////////////////////

  {
    id: '40-4040',
    name: '40x40mm',
    sub_name: 'Aluminum Extrusion',
    metric: true,
    categories: [
      "extrusions"
    ],
    image: {
      name: "40-4040.png",
      style: {
        padding: '0rem 4rem 2rem 0rem'
      }
    },
    details: {
      width: '40mm',
      height: '40mm',
      length: null,
      series: ['40'],
      profile: ['Single'],
      slot_width: '8mm',
      construction: 'Solid',
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/40-4040.html"],
      //   price: "$0.36 cm",
      //   surcharge: "$2.79",
      // },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/5537T904/"],
        price: "$0.87 cm",
      },
    },
    related_parts: [
      
    ],
  }, 

  //////////////////////////
  /////// 30 Series ////////
  //////////////////////////

  {
    id: '30-3030',
    name: '30x30mm',
    sub_name: 'Aluminum Extrusion',
    metric: true,
    categories: [
      "extrusions"
    ],
    image: {
      name: "30-3030.png",
      style: {
        padding: '2rem 6rem 6rem 0rem'
      }
    },
    details: {
      width: '30mm',
      height: '30mm',
      length: null,
      series: ['30'],
      profile: ['Single'],
      slot_width: '8mm',
      construction: 'Hollow',
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/30-3030.html"],
      //   price: "$0.15 cm",
      //   surcharge: "$2.79",
      // },
      "McMaster": {
        name: "McMaster",
        links: ["https://www.mcmaster.com/5537T902/"],
        price: "$0.49 cm",
      },
    },
    related_parts: [
      
    ],
  }, 

  //////////////////////////
  /////// 15 Series ////////
  //////////////////////////

  {
    id: '1515',
    name: '1.5"x1.5"',
    sub_name: 'Aluminum Extrusion',
    metric: false,
    categories: [
      "extrusions"
    ],
    image: {
      name: "1515.png",
      style: {
        padding: '0rem 4rem 2rem 0rem'
      }
    },
    details: {
      width: '1.5"',
      height: '1.5"',
      length: null,
      series: ['15'],
      profile: ['Single'],
      slot_width: '0.32"',
      construction: 'Solid',
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T802"],
      //   price: '$1.35 in',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/1515.html"],
        price: '$0.82 in',
      },
      // https://8020.net/1515-s.html
    },
    related_parts: [],
  }, 
  {
    id: '1504',
    name: '1.5"x1.5"',
    sub_name: 'Aluminum Extrusion Two Opposite TSlots',
    metric: false,
    categories: [
      "extrusions"
    ],
    image: {
      name: "1504.png",
      style: {
        padding: '0rem 4rem 2rem 0rem'
      }
    },
    details: {
      width: '1.5"',
      height: '1.5"',
      length: null,
      series: ['15'],
      profile: ['Single'],
      slot_width: '0.32"',
      construction: 'Hollow',
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T849/"],
      //   price: '$0.94 in',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/1504.html"],
        price: '$0.68 in',
      },
      // https://8020.net/1504-ls.html
    },
    related_parts: [],
  }, 
  {
    id: '1502ls',
    name: '1.5"x1.5"',
    sub_name: 'Aluminum Extrusion Two Adjacent TSlots',
    metric: false,
    categories: [
      "extrusions"
    ],
    image: {
      name: "1502ls.png",
      style: {
        padding: '0rem 4rem 2rem 0rem'
      }
    },
    details: {
      width: '1.5"',
      height: '1.5"',
      length: null,
      series: ['15'],
      profile: ['Single'],
      slot_width: '0.32"',
      construction: 'Hollow',
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T847/"],
      //   price: '$0.94 in',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/1502-ls.html"],
        price: '$0.68 in',
      },
    },
    related_parts: [],
  }, 
  {
    id: '1517ls',
    name: '1.5"x1.5"',
    sub_name: 'Aluminum Extrusion Round Two Adjacent TSlots',
    metric: false,
    categories: [
      "extrusions"
    ],
    image: {
      name: "1517ls.png",
      style: {
        padding: '0rem 4rem 2rem 0rem'
      }
    },
    details: {
      width: '1.5"',
      height: '1.5"',
      length: null,
      series: ['15'],
      profile: ['Single'],
      slot_width: '0.32"',
      construction: 'Hollow',
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/6575N234/"],
      //   price: '$0.86 in',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/1517-ls.html"],
        price: '$0.60 in',
      },
    },
    related_parts: [],
  }, 
  {
    id: '1503ls',
    name: '1.5"x1.5"',
    sub_name: 'Aluminum Extrusion Three Adjacent TSlots',
    metric: false,
    categories: [
      "extrusions"
    ],
    image: {
      name: "1503ls.png",
      style: {
        padding: '0rem 4rem 2rem 0rem'
      }
    },
    details: {
      width: '1.5"',
      height: '1.5"',
      length: null,
      series: ['15'],
      profile: ['Single'],
      slot_width: '0.32"',
      construction: 'Hollow',
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T541/"],
      //   price: '$0.86 in',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/1503-ls.html"],
        price: '$0.68 in',
      },
    },
    related_parts: [],
  }, 
  {
    id: '1501ls',
    name: '1.5"x1.5"',
    sub_name: 'Aluminum Extrusion Single TSlots',
    metric: false,
    categories: [
      "extrusions"
    ],
    image: {
      name: "1501ls.png",
      style: {
        padding: '0rem 4rem 2rem 0rem'
      }
    },
    details: {
      width: '1.5"',
      height: '1.5"',
      length: null,
      series: ['15'],
      profile: ['Single'],
      slot_width: '0.32"',
      construction: 'Hollow',
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/6575N228/"],
      //   price: '$1.02 in',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/1501-ls.html"],
        price: '$0.68 in',
      },
    },
    related_parts: [],
  }, 
  {
    id: '1530',
    name: '1.5"x3"',
    sub_name: 'Aluminum Extrusion',
    metric: false,
    categories: [
      "extrusions"
    ],
    image: {
      name: "1530.png",
      style: {
        padding: '0rem 4rem 2rem 0rem'
      }
    },
    details: {
      width: '1.5"',
      height: '3"',
      length: null,
      series: ['15'],
      profile: ['Double'],
      slot_width: '0.32"',
      construction: 'Solid',
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T804/"],
      //   price: '$2.15 in',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/1530-s.html"],
        price: '$1.42 in',
      },
    },
    related_parts: [],
  }, 
  {
    id: '1545',
    name: '1.5"x4.5"',
    sub_name: 'Aluminum Extrusion',
    metric: false,
    categories: [
      "extrusions"
    ],
    image: {
      name: "1545.png",
      style: {
        padding: '0rem 4rem 2rem 0rem'
      }
    },
    details: {
      width: '1.5"',
      height: '4.5"',
      length: null,
      series: ['15'],
      profile: ['Triple'],
      slot_width: '0.32"',
      construction: 'Solid',
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T521/"],
      //   price: '$3.13 in',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/1545-ls.html"],
        price: '$1.77 in',
      },
    },
    related_parts: [],
  }, 
  {
    id: '3030',
    name: '3"x3"',
    sub_name: 'Aluminum Extrusion',
    metric: false,
    categories: [
      "extrusions"
    ],
    image: {
      name: "3030.png",
      style: {
        padding: '0rem 4rem 2rem 0rem'
      }
    },
    details: {
      width: '3"',
      height: '3"',
      length: null,
      series: ['15'],
      profile: ['Quad'],
      slot_width: '0.32"',
      construction: 'Solid',
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T806/"],
      //   price: '$2.87 in',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/3030-s.html"],
        price: '$2.25 in',
      },
    },
    related_parts: [],
  }, 
  {
    id: '1575',
    name: '1.5"x0.75"',
    sub_name: 'Aluminum Extrusion Single T-Slot',
    metric: false,
    categories: [
      "extrusions"
    ],
    image: {
      name: "1575.png",
      style: {
        padding: '0rem 4rem 2rem 0rem'
      }
    },
    details: {
      width: '1.5"',
      height: '0.75"',
      length: null,
      series: ['15'],
      profile: ['Single'],
      slot_width: '0.32"',
      construction: 'Solid',
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/6575N226/"],
      //   price: '$0.64 in',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/1575.html"],
        price: '$0.45 in',
      },
    },
    related_parts: [],
  }, 
  {
    id: '3075',
    name: '3"x0.75"',
    sub_name: 'Aluminum Extrusion Double T-Slot',
    metric: false,
    categories: [
      "extrusions"
    ],
    image: {
      name: "3075.png",
      style: {
        padding: '0rem 8rem 2rem 0rem'
      }
    },
    details: {
      width: '3"',
      height: '0.75"',
      length: null,
      series: ['15'],
      profile: ['Double'],
      slot_width: '0.32"',
      construction: 'Solid',
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/6575N227/"],
      //   price: '$1.22 in',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/3075.html"],
        price: '$0.89 in',
      },
    },
    related_parts: [],
  }, 
  {
    id: '4332',
    name: '2 Hole',
    sub_name: 'Gusseted Inside Corner Bracket',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4332.png",
      style: {
        padding: '4rem'
      }
    },
    details: {
      width: '1.31"',
      height: '1.5"',
      length: '1.5"',
      series: ['15'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T679/"],
      //   price: '$11.55',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4332.html"],
        price: '$6.43',
      },
    },
    related_parts: [],
  }, 
  {
    id: '4336',
    name: 'Extended Gusseted Corner Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4336.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '1.31"',
      height: '3"',
      length: '3"',
      series: ['15'],
      profile: ['Single', 'Double'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T762/"],
      //   price: '$18.12',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4336.html"],
        price: '$9.33',
      },
    },
    related_parts: [],
  },
  {
    id: '4302',
    name: 'Corner Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4302.png",
      style: {
        padding: '4rem'
      }
    },
    details: {
      width: '1.31"',
      height: '1.5"',
      length: '1.5"',
      series: ['15'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T845/"],
      //   price: '$8.80',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4302.html"],
        price: '$4.41',
      },
    },
    related_parts: [],
  }, 
  {
    id: '4301',
    name: 'Extended Corner Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4301.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '1.31"',
      height: '3"',
      length: '3"',
      series: ['15'],
      profile: ['Single', 'Double'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T241/"],
      //   price: '$9.54',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4301.html"],
        price: '$6.43',
      },
      // https://8020.net/4301.html
    },
    related_parts: [],
  },
  // {
  //   id: '5537T51',
  //   name: 'Closed Gusseted Corner Bracket',
  //   sub_name: '',
  //   metric: false,
  //   categories: [
  //     "brackets"
  //   ],
  //   image: {
  //     name: "5537T51.png",
  //     style: {
  //       padding: '0rem 4rem 2rem 0rem'
  //     }
  //   },
  //   details: {
  //     width: '38mm',
  //     height: '36mm',
  //     length: '36mm',
  //     series: ['15'],
  //     profile: ['Single'],
  //     slot_width: null,
  //     construction: null,
  //     bolt_width: 'M8',
  //     bolt_length: '16mm',
  //   },
  //   vendors: {
  //     "McMaster": {
  //       name: "McMaster",
  //       links: ["https://www.mcmaster.com/5537T51/"],
  //       price: '$9.82',
  //     },
  //   },
  //   related_parts: [],
  // }, 
  // {
  //   id: '6812N831',
  //   name: 'Quick-Install Corner Bracket',
  //   sub_name: '',
  //   metric: false,
  //   categories: [
  //     "brackets"
  //   ],
  //   image: {
  //     name: "6812N831.png",
  //     style: {
  //       padding: '4rem'
  //     }
  //   },
  //   details: {
  //     width: '1.575"',
  //     height: '1.575"',
  //     length: '1.575"',
  //     series: ['15'],
  //     profile: ['Single'],
  //     slot_width: null,
  //     construction: null,
  //     bolt_width: null,
  //     bolt_length: null,
  //   },
  //   vendors: {
  //     "McMaster": {
  //       name: "McMaster",
  //       links: ["https://www.mcmaster.com/6812N831/"],
  //       price: '$10.19',
  //     },
  //   },
  //   related_parts: [],
  // }, 
  // {
  //   id: '6812N832',
  //   name: 'Extended Quick-Install Corner Bracket',
  //   sub_name: '',
  //   metric: false,
  //   categories: [
  //     "brackets"
  //   ],
  //   image: {
  //     name: "6812N832.png",
  //     style: {
  //       padding: '2rem'
  //     }
  //   },
  //   details: {
  //     width: '1.575"',
  //     height: '3.15"',
  //     length: '3.15"',
  //     series: ['15'],
  //     profile: ['Single', 'Double'],
  //     slot_width: null,
  //     construction: null,
  //     bolt_width: null,
  //     bolt_length: null,
  //   },
  //   vendors: {
  //     "McMaster": {
  //       name: "McMaster",
  //       links: ["https://www.mcmaster.com/6812N832/"],
  //       price: '$16.67',
  //     },
  //   },
  //   related_parts: [],
  // }, 
  {
    id: '4338',
    name: 'Extended Gusseted Corner Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4338.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '2 13/16"',
      height: '3"',
      length: '3"',
      series: ['15'],
      profile: ['Double'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T906/"],
      //   price: '$28.78',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4338.html"],
        price: '$13.36',
      },
    },
    related_parts: [],
  }, 
  {
    id: '4304',
    name: 'Extended Corner Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4304.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '2 13/16"',
      height: '3"',
      length: '3"',
      series: ['15'],
      profile: ['Double'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T254/"],
      //   price: '$16.13',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4338.html"],
        price: '$9.78',
      },
    },
    related_parts: [],
  }, 
  {
    id: '4303',
    name: 'Corner Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4303.png",
      style: {
        padding: '4rem'
      }
    },
    details: {
      width: '2.81"',
      height: '1.5"',
      length: '1.5"',
      series: ['15'],
      profile: ['Double'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T238/"],
      //   price: '$13.75',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4303.html"],
        price: '$6.58',
      },
    },
    related_parts: [],
  }, 
  {
    id: '4334',
    name: 'Gusseted Corner Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4334.png",
      style: {
        padding: '4rem'
      }
    },
    details: {
      width: '2.81"',
      height: '1.5"',
      length: '1.5"',
      series: ['15'],
      profile: ['Double'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T858/"],
      //   price: '$18.12',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4334.html"],
        price: '$9.33',
      },
    },
    related_parts: [],
  }, 
  {
    id: '4441',
    name: '3-Way Corner Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4441.png",
      style: {
        padding: '4rem'
      }
    },
    details: {
      width: '1.5"',
      height: '1.5"',
      length: '1.5"',
      series: ['15'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '1 1/4"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/3136N186/"],
      //   price: '$43.12',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4441.html"],
        price: '$31.31',
      },
    },
    related_parts: [],
  }, 
  {
    id: '4307',
    name: '2 Hole Straight Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4307.png",
      style: {
        padding: '4rem'
      }
    },
    details: {
      width: '1.5"',
      height: '1/4"',
      length: '3"',
      series: ['15'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T256/"],
      //   price: '$8.60',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4307.html"],
        price: '$5.17',
      },
    },
    related_parts: [],
  }, 
  {
    id: '4305',
    name: '4 Hole Straight Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4305.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '1.5"',
      height: '1/4"',
      length: '6"',
      series: ['15'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T261/"],
      //   price: '$13.46',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4305.html"],
        price: '$7.40',
      },
    },
    related_parts: [],
  }, 
  {
    id: '4331',
    name: '30 Degree Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4331.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '3"',
      height: '1/4"',
      length: '3.5"',
      series: ['15'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T32/"],
      //   price: '$17.58',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4331.html"],
        price: '$8.95',
      },
    },
    related_parts: [],
  }, 
  {
    id: '4346',
    name: '45 Degree Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4346.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '3"',
      height: '1/4"',
      length: '4.5"',
      series: ['15'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T309/"],
      //   price: '$18.25',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4346.html"],
        price: '$9.42',
      },
    },
    related_parts: [],
  },
  {
    id: '4361',
    name: '60 Degree Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4361.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '3"',
      height: '1/4"',
      length: '4.5"',
      series: ['15'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T366/"],
      //   price: '$18.25',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4361.html"],
        price: '$9.42',
      },
    },
    related_parts: [],
  },  
  {
    id: '4351',
    name: '90 Degree Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4351.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '4.5"',
      height: '1/4"',
      length: '4.5"',
      series: ['15'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T271/"],
      //   price: '$13.34',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4351.html"],
        price: '$10.59',
      },
    },
    related_parts: [],
  },  
  {
    id: '4352',
    name: 'Extended 90 Degree Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4352.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '6"',
      height: '1/4"',
      length: '6"',
      series: ['15'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/3136N149/"],
      //   price: '$24.32',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4352.html"],
        price: '$12.98',
      },
    },
    related_parts: [],
  },  
  {
    id: '4335',
    name: 'Cross Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4335.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '4.5"',
      height: '1/4"',
      length: '7.5"',
      series: ['15'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/3136N154/"],
      //   price: '$28.92',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4335.html"],
        price: '$16.64',
      },
    },
    related_parts: [],
  },  
  {
    id: '4340',
    name: 'Tee Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4340.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '4.5"',
      height: '1/4"',
      length: '4.5"',
      series: ['15'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T279/"],
      //   price: '$13.93',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4340.html"],
        price: '$11.66',
      },
    },
    related_parts: [],
  },  
  {
    id: '4312',
    name: 'Extended Tee Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4312.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '6"',
      height: '1/4"',
      length: '6"',
      series: ['15'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/3136N152/"],
      //   price: '$25.92',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4312.html"],
        price: '$14.25',
      },
    },
    related_parts: [],
  },  
  {
    id: '4480',
    name: 'Flush Tee Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4480.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '4.5"',
      height: '1/4"',
      length: '4.5"',
      series: ['15'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/3136N161/"],
      //   price: '$20.71',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4480.html"],
        price: '$11.94',
      },
    },
    related_parts: [],
  },  
  {
    id: '4481',
    name: 'Flush 90 Degree Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4481.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '4.5"',
      height: '1/4"',
      length: '4.5"',
      series: ['15'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/3136N158/"],
      //   price: '$19.41',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4481.html"],
        price: '$10.90',
      },
    },
    related_parts: [],
  },  
  {
    id: '4367',
    name: '4 Hole Plate Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4367.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '3"',
      height: '1/4"',
      length: '3"',
      series: ['15'],
      profile: ['Single'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T258/"],
      //   price: '$14.79',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4367.html"],
        price: '$7.40',
      },
    },
    related_parts: [],
  },  
  {
    id: '4365',
    name: '8 Hole Plate Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4365.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '3"',
      height: '1/4"',
      length: '6"',
      series: ['15'],
      profile: ['Double', 'Quad'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T264/"],
      //   price: '$16.42',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4365.html"],
        price: '$10.22',
      },
    },
    related_parts: [],
  }, 
  {
    id: '4328',
    name: '90 Degree Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4328.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '6"',
      height: '1/4"',
      length: '6"',
      series: ['15'],
      profile: ['Double', 'Quad'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T277/"],
      //   price: '$36.60',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4328.html"],
        price: '$19.25',
      },
    },
    related_parts: [],
  },  
  {
    id: '4325',
    name: 'Tee Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4325.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '6"',
      height: '1/4"',
      length: '6"',
      series: ['15'],
      profile: ['Double', 'Quad'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/47065T282/"],
      //   price: '$38.77',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4325.html"],
        price: '$19.25',
      },
    },
    related_parts: [],
  },  
  {
    id: '4370',
    name: 'Cross Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4370.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '6"',
      height: '1/4"',
      length: '9"',
      series: ['15'],
      profile: ['Double', 'Quad'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/3136N156/"],
      //   price: '$47.64',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4370.html"],
        price: '$23.42',
      },
    },
    related_parts: [],
  }, 
  {
    id: '4330',
    name: '30 Degree Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4330.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '3"',
      height: '1/4"',
      length: '5"',
      series: ['15'],
      profile: ['Single', 'Double', 'Quad'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/3136N196/"],
      //   price: '$20.18',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4330.html"],
        price: '$10.59',
      },
    },
    related_parts: [],
  },  
  {
    id: '4345',
    name: '45 Degree Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4345.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '3"',
      height: '1/4"',
      length: '6"',
      series: ['15'],
      profile: ['Single', 'Double', 'Quad'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/3136N197/"],
      //   price: '$20.18',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4345.html"],
        price: '$10.59',
      },
    },
    related_parts: [],
  },  
  {
    id: '4362',
    name: '60 Degree Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4362.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '3"',
      height: '1/4"',
      length: '6"',
      series: ['15'],
      profile: ['Single', 'Double', 'Quad'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/3136N198/"],
      //   price: '$20.18',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4362.html"],
        price: '$10.59',
      },
    },
    related_parts: [],
  },  
  {
    id: '4320',
    name: '90 Degree Bracket',
    sub_name: '',
    metric: false,
    categories: [
      "brackets"
    ],
    image: {
      name: "4320.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '3"',
      height: '1/4"',
      length: '6"',
      series: ['15'],
      profile: ['Single', 'Double', 'Quad'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '11/16"',
    },
    vendors: {
      // "McMaster": {
      //   name: "McMaster",
      //   links: ["https://www.mcmaster.com/3136N195/"],
      //   price: '$19.61',
      // },
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/4320.html"],
        price: '$10.15',
      },
    },
    related_parts: [],
  }, 
  
  



  //////////////////////////
  /////// Feet ////////
  //////////////////////////
  {
    id: '2426&2321',
    name: 'Swivel Caster Kit',
    sub_name: '',
    metric: false,
    categories: [
      "mountingFeet"
    ],
    image: {
      name: "2426&2321.png",
      style: {
        padding: '0rem'
      }
    },
    details: {
      width: '3.625"',
      height: '5.125"',
      length: '2.375"',
      series: ['15'],
      profile: ['Single', 'Double', 'Quad'],
      slot_width: null,
      construction: null,
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/2426.html", "https://8020.net/2321.html"],
        price: '$61.15',
      },
    },
    related_parts: [
      '2426', '2321'
    ],
  },
  {
    id: '2426',
    hidden: true,
    name: 'Flange Mount Caster Base Plate',
    sub_name: '',
    metric: false,
    categories: [
      "mountingFeet"
    ],
    image: {
      name: "2426.png",
      style: {
        padding: '4rem'
      }
    },
    details: {
      width: '3"',
      height: '0.75"',
      length: '3.75"',
      series: ['15'],
      profile: ['Single', 'Double', 'Quad'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '1.3"',
    },
    vendors: {
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/2426.html"],
        price: '$27.81',
      },
    },
    related_parts: [
      
    ],
  },
  {
    id: '2321',
    hidden: true,
    name: '4.00"',
    sub_name: 'Flanged Mount Swivel Caster',
    metric: false,
    categories: [
      "mountingFeet"
    ],
    image: {
      name: "2321.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '3.625"',
      height: '5.125"',
      length: '2.375"',
      series: ['15'],
      profile: ['Single', 'Double', 'Quad'],
      slot_width: null,
      construction: null,
      bolt_width: '5/16',
      bolt_length: '0.625"',
    },
    vendors: {
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/2321.html"],
        price: '$33.34',
      },
    },
    related_parts: [
      
    ],
  },



  //////////////////////////
  /////////. Bins //////////
  //////////////////////////
  {
    id: '2270&2264',
    name: 'Storage Bin Kit',
    sub_name: 'Large',
    metric: false,
    categories: [
      "organization"
    ],
    image: {
      name: "2270&2264.png",
      style: {
        padding: '0rem'
      }
    },
    details: {
      width: '11"',
      height: '5"',
      length: '10.875"',
      series: ['15', '10'],
      profile: ['Single', 'Double', 'Quad'],
      slot_width: null,
      construction: null,
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/2270-red.html", "https://8020.net/2264.html"],
        price: '$27.62',
      },
    },
    related_parts: [
      '2270', '2264'
    ],
  },
  {
    id: '2230&2264',
    name: 'Storage Bin Kit',
    sub_name: 'Small',
    metric: false,
    categories: [
      "organization"
    ],
    image: {
      name: "2230&2264.png",
      style: {
        padding: '0rem'
      }
    },
    details: {
      width: '4.125"',
      height: '3"',
      length: '5.375"',
      series: ['15', '10'],
      profile: ['Single', 'Double', 'Quad'],
      slot_width: null,
      construction: null,
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/2230-red.html", "https://8020.net/2264.html"],
        price: '$12.44',
      },
    },
    related_parts: [
      '2230', '2264'
    ],
  },
  {
    id: '2264',
    hidden: true,
    name: 'Storage Bin Hanger',
    sub_name: '',
    metric: false,
    categories: [
      "organization"
    ],
    image: {
      name: "2264.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '6"',
      height: '0.250"',
      length: '1.5"',
      series: ['15', '10'],
      profile: ['Single', 'Double', 'Quad'],
      slot_width: null,
      construction: null,
      bolt_width: '1/4',
      bolt_length: '0.625"',
    },
    vendors: {
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/2264.html"],
        price: '$10.22',
      },
    },
    related_parts: [
      
    ],
  },
  {
    id: '2270',
    hidden: true,
    name: 'Storage Bin',
    sub_name: 'Large',
    metric: false,
    categories: [
      "organization"
    ],
    image: {
      name: "2270.png",
      style: {
        padding: '0rem'
      }
    },
    details: {
      width: '11"',
      height: '5"',
      length: '10.875"',
      series: ['15', '10'],
      profile: ['Single', 'Double', 'Quad'],
      slot_width: null,
      construction: null,
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/2270-red.html"],
        price: '$17.40',
      },
    },
    related_parts: [
      
    ],
  },
  {
    id: '2230',
    hidden: true,
    name: 'Storage Bin',
    sub_name: 'Small',
    metric: false,
    categories: [
      "organization"
    ],
    image: {
      name: "2230.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '4.125"',
      height: '3"',
      length: '5.375"',
      series: ['15', '10'],
      profile: ['Single', 'Double', 'Quad'],
      slot_width: null,
      construction: null,
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/2230-red.html"],
        price: '$2.22',
      },
    },
    related_parts: [
      
    ],
  },






   //////////////////////////
  /////////. Doors //////////
  //////////////////////////
  {
    id: '2061',
    name: 'Plastic Door Handle',
    sub_name: '',
    metric: false,
    categories: [
      "doors"
    ],
    image: {
      name: "2061.png",
      style: {
        padding: '2rem'
      }
    },
    details: {
      width: '1.024"',
      height: '1.614"',
      length: '5.472"',
      series: ['15'],
      profile: ['Single', 'Double', 'Quad'],
      slot_width: null,
      construction: null,
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {
      "80/20": {
        name: "80/20",
        links: ['https://8020.net/2061.html'],
        price: '$9.77',
      },
    },
    related_parts: [],
  },





  
  //////////////////////////
  /////////. Doors //////////
  //////////////////////////
  {
    id: '2602',
    name: 'Clear Acrylic Panel',
    sub_name: '0.177" thick',
    metric: false,
    categories: [
      "panels"
    ],
    image: {
      name: "2602.png",
      style: {
        padding: '0rem'
      }
    },
    details: {
      width: null,
      height: '0.177"',
      length: null,
      series: ['15'],
      profile: [],
      slot_width: null,
      construction: null,
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {
      "80/20": {
        name: "80/20",
        links: ['https://8020.net/2602.html'],
        price: '$8.43 sqft',
      },
    },
    related_parts: [],
  },
  {
    id: '2654',
    name: 'White HDPE Panel',
    sub_name: '0.177" thick',
    metric: false,
    categories: [
      "panels"
    ],
    image: {
      name: "2654.png",
      style: {
        padding: '0rem'
      }
    },
    details: {
      width: null,
      height: '0.177"',
      length: null,
      series: ['15'],
      profile: [],
      slot_width: null,
      construction: null,
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {
      "80/20": {
        name: "80/20",
        links: ['https://8020.net/2654.html'],
        price: '$4.87 sqft',
      },
    },
    related_parts: [],
  },






  //////////////////////////
  /////// Feet ////////
  //////////////////////////

  {
    id: '2186',
    name: 'Economy Floor Glide',
    sub_name: '',
    metric: true,
    categories: [
      "feet"
    ],
    image: {
      name: "2186.png",
      style: {
        padding: '1rem'
      }
    },
    details: {
      width: '1.200',
      height: '1.200"',
      length: '2.250"',
      series: [],
      profile: [],
      slot_width: null,
      construction: null,
      bolt_width: 'M12',
      bolt_length: "30mm",
    },
    vendors: {
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/2186.html"],
        price: '$2.30',
      },
    },
    related_parts: [
      
    ],
  },






  //////////////////////////
  /////// Fasteners ////////
  //////////////////////////
  {
    id: '13003',
    name: 'M12 x 30mm',
    sub_name: 'Connecting Screw',
    metric: true,
    categories: [
      "hardware"
    ],
    image: {
      name: "BoltRound.png",
      style: {
        padding: '2rem 6rem 6rem 0rem'
      }
    },
    details: {
      width: null,
      height: null,
      length: null,
      series: [],
      profile: [],
      slot_width: null,
      construction: null,
      bolt_width: 'M12',
      bolt_length: "30mm",
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/13003.html"],
      //   price: '$2.30',
      // },
    },
    related_parts: [
      
    ],
  },
  {
    id: '75-3625',
    name: 'M8 x 16mm',
    sub_name: 'Bolt Assembly',
    metric: true,
    categories: [
      "boltAssembly"
    ],
    image: {
      name: "BoltAssemblyFlatXSelfAlign.png",
      style: {
        padding: '0rem'
      }
    },
    details: {
      width: null,
      height: null,
      length: null,
      series: ['15','30','40'],
      profile: [],
      slot_width: null,
      construction: null,
      bolt_width: 'M8',
      bolt_length: '16mm',
      t_nut: "Self-Aligning Slide-in"
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/75-3625.html"],
      //   price: "$2.74",
      // },
    },
    related_parts: [
      
    ],
  },
  {
    id: '75-3623',
    name: 'M8 x 16mm',
    sub_name: 'Bolt Assembly',
    metric: true,
    categories: [
      "boltAssembly"
    ],
    image: {
      name: "BoltAssemblyFlatXHammer.png",
      style: {
        padding: '0rem'
      }
    },
    details: {
      width: null,
      height: null,
      length: null,
      series: ['45'],
      profile: [],
      slot_width: null,
      construction: null,
      bolt_width: 'M8',
      bolt_length: '16mm',
      t_nut: "Drop-in"
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/75-3623.html"],
      //   price: "$1.51",
      // },
    },
    related_parts: [
      
    ],
  },
  {
    id: '75-3624',
    name: 'M8 x 12mm',
    sub_name: 'Bolt Assembly',
    metric: true,
    categories: [
      "boltAssembly"
    ],
    image: {
      name: "BoltAssemblyFlatXSelfAlign.png",
      style: {
        padding: '0rem'
      }
    },
    details: {
      width: null,
      height: null,
      length: null,
      series: ['30'],
      profile: [],
      slot_width: null,
      construction: null,
      bolt_width: 'M8',
      bolt_length: '12mm',
      t_nut: "Self-Aligning Slide-In"
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/75-3624.html"],
      //   price: "$2.72",
      // },
    },
    related_parts: [
      
    ],
  },
  {
    id: '11-6516',
    name: 'M6 x 16mm',
    sub_name: 'Bolt',
    metric: true,
    categories: [
      "hardware"
    ],
    image: {
      name: "BoltFlat.png",
      style: {
        padding: '0rem'
      }
    },
    details: {
      width: null,
      height: null,
      length: null,
      series: ['45'],
      profile: [],
      slot_width: null,
      construction: null,
      bolt_width: 'M6',
      bolt_length: '16mm',
      t_nut: null
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/11-6516.html"],
      //   price: "$0.53",
      // },
    },
    related_parts: [
      
    ],
  },
  {
    id: '13129',
    name: 'M6',
    sub_name: 'Drop-in T-Nut 45',
    metric: true,
    categories: [
      "hardware"
    ],
    image: {
      name: "NutHammer.png",
      style: {
        padding: '0rem'
      }
    },
    details: {
      width: null,
      height: null,
      length: null,
      series: ['45'],
      profile: [],
      slot_width: null,
      construction: null,
      bolt_width: null,
      bolt_length: null,
      t_nut: "Drop-in"
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/13129.html"],
      //   price: "$1.07",
      // },
    },
    related_parts: [
      
    ],
  },
  {
    id: '75-3619',
    name: 'M8 x 18mm',
    sub_name: 'Bolt Assembly',
    metric: true,
    categories: [
      "boltAssembly"
    ],
    image: {
      name: "BoltAssemblyRoundXHammer.png",
      style: {
        padding: '0rem'
      }
    },
    details: {
      width: null,
      height: null,
      length: null,
      series: ['45'],
      profile: [],
      slot_width: null,
      construction: null,
      bolt_width: 'M8',
      bolt_length: '18mm',
      t_nut: "Drop-In"
    },
    vendors: {
      // "80/20": {
      //   name: "80/20",
      //   links: ["https://8020.net/75-3619.html"],
      //   price: "$1.66",
      // },
    },
    related_parts: [
      
    ],
  },
  {
    id: '3320',
    name: '5/16-18 x .687"',
    sub_name: 'Bolt Assembly',
    metric: true,
    categories: [
      "boltAssembly"
    ],
    image: {
      name: "BoltAssemblyRoundXHammer.png",
      style: {
        padding: '0rem'
      }
    },
    details: {
      width: null,
      height: null,
      length: null,
      series: ['15'],
      profile: [],
      slot_width: null,
      construction: null,
      bolt_width: '5/16"',
      bolt_length: '0.687"',
      t_nut: "Drop-In"
    },
    vendors: {
      "80/20": {
        name: "80/20",
        links: ["https://8020.net/3320.html"],
        price: "$0.95",
      },
    },
    related_parts: [
      
    ],
  },

  //////////////////////////
  ///////// SHAPES /////////
  //////////////////////////

  {
    id: 'cube',
    name: 'Cube',
    sub_name: null,
    metric: true,
    categories: [
      "shape"
    ],
    image: {
      name: "Cube.png",
      style: {
        padding: '2rem 2rem'
      }
    },
    details: {
      width: null,
      height: null,
      length: null,
      series: null,
      profile: null,
      slot_width: null,
      construction: null,
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {},
    related_parts: [
      'cylinder',
      'sphere',
      'cone',
      'pyramid',
      'diamond',
    ],
  }, 
  {
    id: 'cylinder',
    name: 'Cylinder',
    sub_name: null,
    metric: true,
    categories: [
      "shape"
    ],
    image: {
      name: "Cylinder.png",
      style: {
        padding: '2rem 2rem'
      }
    },
    details: {
      width: null,
      height: null,
      length: null,
      series: null,
      profile: null,
      slot_width: null,
      construction: null,
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {},
    related_parts: [
      'cube',
      'sphere',
      'cone',
      'pyramid',
      'diamond',
    ],
  }, 
  {
    id: 'sphere',
    name: 'Sphere',
    sub_name: null,
    metric: true,
    categories: [
      "shape"
    ],
    image: {
      name: "Sphere.png",
      style: {
        padding: '2rem 2rem'
      }
    },
    details: {
      width: null,
      height: null,
      length: null,
      series: null,
      profile: null,
      slot_width: null,
      construction: null,
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {},
    related_parts: [
      'cube',
      'cylinder',
      'cone',
      'pyramid',
      'diamond',
    ],
  }, 
  {
    id: 'cone',
    name: 'Cone',
    sub_name: null,
    metric: true,
    categories: [
      "shape"
    ],
    image: {
      name: "Cone.png",
      style: {
        padding: '2rem 2rem'
      }
    },
    details: {
      width: null,
      height: null,
      length: null,
      series: null,
      profile: null,
      slot_width: null,
      construction: null,
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {},
    related_parts: [
      'cube',
      'cylinder',
      'sphere',
      'pyramid',
      'diamond',
    ],
  }, 
  {
    id: 'pyramid',
    name: 'Pyramid',
    sub_name: null,
    metric: true,
    categories: [
      "shape"
    ],
    image: {
      name: "Pyramid.png",
      style: {
        padding: '2rem 2rem'
      }
    },
    details: {
      width: null,
      height: null,
      length: null,
      series: null,
      profile: null,
      slot_width: null,
      construction: null,
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {},
    related_parts: [
      'cube',
      'cylinder',
      'sphere',
      'cone',
      'diamond',
    ],
  }, 
  {
    id: 'diamond',
    name: 'Diamond',
    sub_name: null,
    metric: true,
    categories: [
      "shape"
    ],
    image: {
      name: "Diamond.png",
      style: {
        padding: '2rem 2rem'
      }
    },
    details: {
      width: null,
      height: null,
      length: null,
      series: null,
      profile: null,
      slot_width: null,
      construction: null,
      bolt_width: null,
      bolt_length: null,
    },
    vendors: {},
    related_parts: [
      'cube',
      'cylinder',
      'sphere',
      'cone',
      'pyramid',
    ],
  }, 
]