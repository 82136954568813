<script>
import ConfirmArea from "@/components/atoms/ConfirmArea";
import Terms from "@/components/atoms/Terms";
import Connecting from "@/components/atoms/Connecting";
export default {
  components: { ConfirmArea, Terms, Connecting },
  created() {
    // Save Logs
    let current_log = console.log;
    const log = console.log;
    console.log = (msg) => {
      if (msg !== undefined) this.console_data.push(msg);
      current_log.apply(null, arguments);
      log(msg);
    };
    // Vr Hover
    if (window.vuplex) {
      this.addMessageListener();
    } else {
      window.addEventListener('vuplexready', this.addMessageListener);
    }
  },
  mounted(){
    this.$store.set('payment/showConnectingToStripe', false);
  },
  data() {
    return {
      last_hover: null,
      last_data_vr: null,
      console_data: [],
    };
  },
  computed: {
    UseMetric(){
      return this.$store.get('useMetric');
    },
    roundLength(){
      return this.$store.get('roundLength');
    },
    roundPosition(){
      return this.$store.get('roundPosition');
    },
    roundLengthInchIncrement(){
      return this.$store.get('roundLengthInchIncrement');
    },
    roundLengthMeterIncrement(){
      return this.$store.get('roundLengthMeterIncrement');
    },
    roundPositionInchIncrement(){
      return this.$store.get('roundPositionInchIncrement');
    },
    roundPositionMeterIncrement(){
      return this.$store.get('roundPositionMeterIncrement');
    },
  },
  watch: {
    UseMetric(value){
      this.$store.dispatch("postMessage", "useMetric:"+value);
    },
    roundLength(value){
      this.$store.dispatch("postMessage", "roundLength:"+value);
    },
    roundPosition(value){
      this.$store.dispatch("postMessage", "roundPosition:"+value);
    },
    roundLengthInchIncrement(value){
      this.$store.dispatch("postMessage", "roundLengthInchIncrement:"+value)
    },
    roundLengthMeterIncrement(value){
      this.$store.dispatch("postMessage", "roundLengthMeterIncrement:"+value)
    },
    roundPositionInchIncrement(value){
      this.$store.dispatch("postMessage", "roundPositionInchIncrement:"+value)
    },
    roundPositionMeterIncrement(value){
      this.$store.dispatch("postMessage", "roundPositionMeterIncrement:"+value)
    }
  },
  methods: {
    addMessageListener(){
      this.$gtag.event('vr_session');
      this.$store.set('vr', true);
      window.vuplex.addEventListener("message", (event) => {
        let json = JSON.parse(event.data);

        if (json.type == "position") {
          const hovering = document.elementFromPoint(
            Number(json.x),
            Number(json.y)
          );
          if (this.last_hover !== hovering) {
            if (this.last_hover) {
              this.last_hover.classList.remove("hover");
            }
            this.last_hover = hovering;
            hovering.classList.add("hover");

            if (hovering.hasAttribute("data-vr")) {
              this.last_data_vr = hovering.getAttribute("data-vr");
              this.$store.dispatch("postMessage", "ID:"+this.last_data_vr);
            }else{
              this.$store.dispatch("postMessage", "ID:null");
            }
          }
        } else if (json.type == "secondaryThumbstick") {
          if(Math.abs(json.x) > Math.abs(json.y)){
            const parent = this.getScrollParent(this.last_hover);
            if(parent){
              parent.scrollLeft += json.x*20;
            }
          }else{
            window.scrollBy(0, -json.y*20);
          }
        } else if (json.type == "grab") {
          console.log("grab");
        } else if(json.type == "save"){
          this.$store.dispatch("saveFiles/saveFile", json.data)
        } else if(json.type == "goto"){
          this.$router.push(json.path)
        }else if(json.type == "key"){
          const key_callback = this.$store.state.key_callback;
          if(key_callback instanceof Function){
            key_callback(json.key)
          }
        }else if(json.type == "image"){
          this.$store.dispatch("saveFiles/saveImage", {
            base64String: json.data,
            name: json.name,
          });
        }else if(json.type == "loaded"){
          this.$store.commit("saveFiles/loaded", json.id);
        }else if(json.type == "confirmLoad"){
          this.$store.dispatch("saveFiles/confirmLoad", {id: json.id, name: json.name});
        }
      });
    },
    getScrollParent(node) {
      if (node == null) {
        return null;
      }

      if (node.scrollWidth > node.clientWidth) {
        return node;
      } else {
        return this.getScrollParent(node.parentNode);
      }
    }
  }
};
</script>

<template>
  <div id="app">
    <ConfirmArea id="confirm-area"></ConfirmArea>
    <Terms v-if="$store.get('show_terms_of_use')" link="TermsofUse"/>
    <Terms v-if="$store.get('show_privacy_policy')" link="PrivacyPolicy"/>
    <Connecting v-if="$store.get('payment/showConnectingToStripe')" />
    <div v-if="true" class="console">
      <div v-for="(line, index) in console_data" :key="index">
        {{line}}
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');
@import "~@/styles/constants";
html {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    font-family: "Montserrat", sans-serif;
    color: $w;
    background: $page_background;
    #app {
      width: 100%;
      height: 100%;
      font-weight: 600;
    }
  }
  .pac-container{
    background-color: $black_2;
    &::after{
      display: none;
      opacity: 0;
    }
    .pac-item{
      color: $w_1;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 1rem;
      border-top: 1px solid $black_4;
      height: 3rem;
      display: flex;
      align-items: center;
      font-size: 1.15rem;
      padding: 0rem 1rem;
      &:hover{
        background-color: $black_3;
      }
      .pac-icon{
        color: #FFF;
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
      }
      .pac-item-query{
        color: #FFF;
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        font-size: 1.15rem;
      }
    }
  }
}
div {
  box-sizing: border-box;
}
.console {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  width: 100vw;
  pointer-events: none;
}
.fade-enter-active {
  transition: all 0.6s;
}
.fade-leave-active {
  transition: all 0.4s;
}
.fade-enter {
  opacity: 0;
}
.fade-leave-to {
  opacity: 0;
}
</style>
