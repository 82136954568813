<script>
import PrivacyPolicy from '../../../public/PrivacyPolicy.vue';
import TermsofUse from '../../../public/TermsofUse.vue';
export default {
  components: { PrivacyPolicy, TermsofUse },
  props: ["link"],
  data(){
    return {
      origin: window.location.origin
    }
  }
}
</script>

<template>
  <div class="terms-page">
    <div class="terms-module" v-click-outside="() => {$store.set('show_terms_of_use', false); $store.set('show_privacy_policy', false)}">
      <PrivacyPolicy v-if="link == 'PrivacyPolicy'"/>
      <TermsofUse v-if="link == 'TermsofUse'"/>
    </div>
    <span class="fa fa-times" @click="() => {$store.set('show_terms_of_use', false); $store.set('show_privacy_policy', false)}"></span>
  </div>
</template>

<style lang="scss">
@import "~@/styles/constants";
.terms-page{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0rem;
  left: 0rem;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(50,50,50,0.5);
  .terms-module{
    margin: 2rem 0rem 2rem 2rem;
    width: 60rem;
    max-height: calc(100% - 10rem);
    background-color: #FFF;
    padding: 2rem;
    overflow: auto;
    color: $black_2;
  }
  .fa{
    margin-top: 2rem;
    margin-right: 1rem;
    padding: 2rem;
    height: calc(100% - 10rem);
    font-size: 2rem;
    color: $w_1;
  }
}
</style>