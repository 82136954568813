<script>
import "@/lib/unity";

import Banner from "@/components/atoms/Banner";
import Search from "@/components/atoms/Search";

export default {
  components: { Banner, Search },
};
</script>

<template>
  <div id="library-page">
    <Banner>
      <template v-slot:banner>
        <div class="custom-banner-area">
          <div v-if="false" class="search">
            <Search></Search>
          </div>
        </div>
      </template>
      <div v-if="!$store.get('auth/signed_in') || $store.get('useMetric') !== null">
        <router-view></router-view>
      </div>
    </Banner>
  </div>
</template>

<style lang="scss">
@import "~@/styles/constants";
#library-page {
  .custom-banner-area{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-area{
      display: flex;
      align-items: center;
      .toggle{
        margin-left: 2rem;
      }
    }
    
  }
}
</style>
