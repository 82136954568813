<script>
  import In from "@/components/atoms/In";
  import Btn from "@/components/atoms/Btn";
  import Banner from "@/components/atoms/Banner";
  import SaveFile from "@/components/atoms/SaveFile";
  import Loading from "@/components/atoms/Loading";
  export default {
    components: { In, Btn, Banner, SaveFile, Loading },
    computed: {
      saveFiles(){
        return this.$store.get('saveFiles/saveFiles')
      }
    }
  }
</script>

<template>
  <Banner>
    <div id="home-page">
      <Loading :loading="$store.get('saveFiles/loading')"/>
      <div class="welcome-module">
        <div class="column left">
          <div class="welcome-title">
            Welcome!
          </div>
          <div class="area-title">
            Quick Links
          </div>
          <div>
            <Btn class="link" :class="{'active': $route.name.toLowerCase() == 'load'}" stretch="full" @click="$router.push('/projects')">
              My Projects
            </Btn>
          </div>
          <div>
            <Btn class="link" :class="{'active': $route.path.includes('parts')}" stretch="full" @click="$router.push('/parts/categories')">
              Part Catalog
            </Btn>
          </div>
        </div>
        <div class="column right" >
          <div class="area-title">
            Recent Project
          </div>
          <template v-if="saveFiles.length > 0">
            <SaveFile :save-file="saveFiles[0]" :show-options="true" @load="$store.dispatch('saveFiles/loadFromCloud', saveFiles[0])" @click="$router.push('/project/'+saveFiles[0].id)"/>
          </template>
          <template v-else>
            <div class="empty">
              No Recent Projects
            </div>
          </template>
        </div>
        
        
      </div>
    </div>
  </Banner>
</template>

<style lang="scss">
@import "~@/styles/constants";
#home-page{
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 5rem);
  .welcome-module{
    padding-bottom: 6rem;
    display: flex;
    flex-wrap: wrap;
    .column{
      margin: 0rem 4rem;
      &.right{
        padding-top: 3rem;
      }
      .welcome-title{
        font-size: 5rem;
        padding: 1rem;
      }
      .area-title{
        font-size: 1.25rem;
        padding: 1rem;
        color: $w_1;
      }
      .card{
        margin-top: 1rem;
        width: 50rem;
      }
      .empty{
        width: 50rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16rem;
        margin-top: 1rem;
        border: 3px solid $black_3;
        border-radius: 8px;
        color: $black_4;
        font-size: 1.5rem;
      }
      .save-file{
        margin-left: -1rem;
      }
      .link{
        font-size: 1.75rem;
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        margin-bottom: 0.5rem;
        font-weight: 700;
        color: #CCC;
        justify-content: flex-start;
        &.active{
          color: $p;
        }
        &:hover, &.hover{
          color: #FFF;
          &.active{
            color: $p;
          }
          cursor: pointer;
        }
        * {
          pointer-events: none;
        }
      }
    }
    
  }
}
</style>