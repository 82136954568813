<script>
import Btn from "@/components/atoms/Btn";
import BtnRow from "@/components/atoms/BtnRow";
import Banner from "@/components/atoms/Banner";
import PartRow from "@/components/atoms/PartRow";
import vendors from "@/data/parts/vendors.js";
import In from "@/components/atoms/In.vue";

export default {
  components: {Banner, Btn, BtnRow, PartRow, In},
  props: ['showOptions'], 
  mounted(){
    const autocomplete = new google.maps.places.Autocomplete(this.$refs["origin"].$refs["origin"]);
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      this.address = place.formatted_address
    })
  },
  data(){
    return {
      first_name: null,
      last_name: null,
      phone_number: null,
      address: null,
      error: null
    }
  },
  watch: {
    
  },
  computed: {
    saveFile(){
      return this.$store.get('saveFiles/saveFilesById', this.$route.params.project_id)
    },
    partLedger(){
      return this.$store.get('saveFiles/getPartLedger', this.$route.params.project_id)
    },
  },
  methods: {
    blur(value){
      if(!value){
        this.$refs['origin'].$refs['origin'].value = null
      }else{
        this.$refs['origin'].$refs['origin'].value = this.address
      }
    },
    validate(){
      if(!this.first_name || !this.last_name || !this.phone_number || !this.address){
        this.error = "*Missing required fields"
      }else{
        this.error = null
      }
    }
  }
  
}
</script>

<template>
  <Banner>
    <div id="checkout">
      <div class="checkout-module">
        <div class="input-row">
          <in v-model="first_name" placeholder="First Name"></in>
          <in v-model="last_name" placeholder="Last Name"></in>
          <in v-model="phone_number" placeholder="Phone Number"></in>
        </div>
        <div class="input-row">
          <in placeholder="Shipping Address" customref="origin" ref="origin" @blur="blur"></in>
        </div>
        <div class="error-row" v-if="error">
          {{error}}
        </div>
        <div class="button-row">
          <btn type="fill" stretch="full" @click="validate">Request Invoice</btn>
        </div>
      </div>
    </div>
  </Banner>
</template>

<style lang="scss">
@import "~@/styles/constants";
#checkout{
  display: flex;
  align-items: center;
  justify-content: center;
  .checkout-module{
    flex-basis: 80rem;
    padding: 1rem;
    .input-row{
      display: flex;
      align-items: center;
      .input{
        padding: 0.5rem;
        flex: 1;
      }
    }
    .error-row{
      color: $error;
      padding: 0.5rem;
    }
    .button-row{
      padding: 0.5rem;
    }
  }
}
</style>