<script>
export default {
  props: ["link"],
  data(){
    return {
      origin: window.location.origin
    }
  }
}
</script>

<template>
  <div class="connecting">
    <div class="connecting-module" v-click-outside="() => $store.set('payment/showConnectingToStripe', false)">
      <div class="title">
        Connecting to
      </div>
      <img :src="'./icons/Stripe.svg'"/>
    </div>
  </div>
</template>

<style lang="scss">
@import "~@/styles/constants";
.connecting{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0rem;
  left: 0rem;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(50,50,50,0.5);
  .connecting-module{
    margin: 2rem;
    padding: 3rem 6rem 2rem 6rem;
    background-color: $black_2;
    .title{
      font-size: 1.25rem;
    }
    img{
      width: 10rem;
    }
  }
}
</style>